<template>
    <AlternativeHeader />

    <div class="container" id="single-product">
        <!-- Breadcrumbs -->
        <div class="row mt-5 mb-4 px-3">
            <div class="col-lg-12 px-0">
                <div class="border p-2 text-uppercase text-gray">
                    <small><a href="/#">Početna</a> / <a href="/lion-expert/proizvodi">Proizvodi</a> / {{productCategories[0].title}} / <strong class="text-dark">{{product.title}}</strong></small>
                </div>
            </div>
        </div>

        <div class="row px-3 mb-5">
            <!-- 360 product image start -->
            <div class="col-lg-6 border p-3 d-flex justify-content-center align-items-center">

                <div v-if="!degreesImageMissing" class="p-1 mx-w-100 mx-2">
                    <div class="cloudimage-360"
                    :data-folder="'/360-images/' + product.slug.current + '/'"
                    data-filename="img-{index}.jpg"
                    data-index-zero-base="4"
                    data-amount="72"
                    data-speed="80"
                    data-drag-speed="250"
                    data-bottom-circle
                    data-autoplay
                    data-play-once
                    data-spin-reverse
                    data-full-screen="true"
                    data-magnifier-in-fullscreen="true"
                    data-ratio="width">
                    </div>
                </div>

                <img style='height: 0; width: 0' @error="onImageLoadFailure($event)" :src="'/360-images/' + product.slug.current + '/img-0001.jpg'" />

                <img class="img-fluid mx-h-300 main-image" v-if="product.image && degreesImageMissing" :src="imageUrlFor(product.image).height(450)" />
            </div>
            <!-- 360 product image end -->

            <!-- Product Description Start -->
            <div class="col-lg-6 description-p d-flex justify-content-center align-items-center">
                <div class="row">
                    <h1 class="section-title">{{product.title}}</h1>
                    <p v-if="productCategories"><i>> {{productCategories[0].title}}</i></p>
                    <SanityBlocks :blocks="blocks" />
                </div>
            </div>
            <!-- Product Description End -->
        </div>

    </div>

</template>

<script>
import AlternativeHeader from "../components/UI/AlternativeHeader.vue";
import { SanityBlocks } from "sanity-blocks-vue-component";
import sanity from "../client";
import imageUrlBuilder from "@sanity/image-url";
import moment from 'moment';

const imageBuilder = imageUrlBuilder(sanity);
const query = `*[_type == "products" && slug.current == $slug] {
  _id, slug, title, createdAt, description, shortDescription, 'categories': categories[]->{title, slug},
 "image": mainImage{
  asset->{
  _id,
  url
}
},
}[0]
`;

export default {
  name: "SingleProduct",
  data() {
    return {
      loading: true,
      product: [],
      blocks: [],
      productCategories: [],
      degreesImageMissing: false
    };
  },
  created() {
    this.fetchData();
  },
   mounted() {
    window.scrollTo(0, 0);
  },
  methods: {
    moment,
    onImageLoadFailure(event) {
        this.degreesImageMissing = true;
    },
    degreesImageExists(slug) {
        if (degreesImages.indexOf(slug) !== -1) {
            return true;
        } else {
            return false;
        }
    },
    imageUrlFor(source) {
      return imageBuilder.image(source);
    },
    fetchData() {
      this.error = this.product = null;
      this.loading = true;

      sanity.fetch(query, { slug: this.$route.params.slug }).then(
        (product) => {
          this.loading = false;
          this.product = product;
          this.blocks = product.description;
          this.productCategories = product.categories;
          setTimeout(function() {
              window.CI360.init();
          }, 100);
        },
        (error) => {
          this.error = error;
        }
      );
    },
  },

  components: {
    AlternativeHeader,
    SanityBlocks,
  },
};
</script>


<style scoped>
a{
    color: inherit;
    text-decoration: none;
}

.border-cat{
    border: 1px solid #dee2e6!important;
}

.description-p{
    padding: 3rem;
}

.border-cat:hover{
    background-color: var(--color-main);
    color: white;
}

.border-cat:hover i{
    color: var(--color-secondary);
}

small a:hover{
  color: var(--color-main);
}

.text-gray{
    color: #BDBDBD;
}

.categories-gap{
    gap: 3rem;
}

::marker {
    color: var(--color-main);
}

@media (max-width: 576px) {
  .categories-gap{
    gap: 0.5rem;
  }

  .description-p{
      padding: 0.5rem;
  }
}

.cloudimage-360 .cloudimage-360-prev,
.cloudimage-360 .cloudimage-360-next {
    padding: 8px;
    background: rgba(255, 255, 255, 0.5);
    border: none;
    border-radius: 4px;
}

.cloudimage-360 .cloudimage-360-prev:focus,
.cloudimage-360 .cloudimage-360-next:focus {
    outline: none;
}

.cloudimage-360 .cloudimage-360-prev {
    display: none;
    position: absolute;
    z-index: 100;
    top: calc(50% - 15px);
    left: 20px;
}

.cloudimage-360 .cloudimage-360-next {
    display: none;
    position: absolute;
    z-index: 100;
    top: calc(50% - 15px);
    right: 20px;
}

.cloudimage-360 .cloudimage-360-prev:before,
.cloudimage-360 .cloudimage-360-next:before {
    content: '';
    display: block;
    width: 30px;
    height: 30px;
    background: 50% 50% / cover no-repeat;
}

.cloudimage-360 .cloudimage-360-prev:before {
background-image: url('https://cdn.scaleflex.it/plugins/js-cloudimage-360-view/assets/img/arrow-left.svg');
}

.cloudimage-360 .cloudimage-360-next:before {
    background-image: url('https://cdn.scaleflex.it/plugins/js-cloudimage-360-view/assets/img/arrow-right.svg');
}

.cloudimage-360 .cloudimage-360-prev.not-active,
.cloudimage-360 .cloudimage-360-next.not-active {
    opacity: 0.4;
    cursor: default;
}

.cloudimage-360 {
    width: 38rem !important;
    max-width: 100%;
    max-height: 105%;
}

.mx-w-100{
    max-width: 100%;
}

</style>
