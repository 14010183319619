<template>
  <!--Section One + Navbar Start-->
  <div class="first-section backdrop-image-dark">
    <div class="header container h-100 d-flex flex-column position-relative overflow-hidden justify-content-start px-0">
      <LandingPageHader />
      <!--Title Box-->
      <img class="bg-mobile position-absolute d-block d-lg-none start-50 translate-middle-x" src="/img/mobile-app-mockup-1.png"
        alt="MobileApplication" />
      <div id="top-section"
        class="container-lg d-flex h-100 flex-column flex-lg-row align-items-center justify-content-end justify-content-lg-start z-100 p-4">
        <img class="d-none d-lg-block" src="/img/mobile-app-mockup-1.png" alt="MobileApplication" />
        <div class="position-relative text-center text-lg-start">
          <h1 class="section-title fw-bold">Putujte bez zastoja</h1>
          <p class="section-paragraph m-0 text-white mt-2">Uz našu aplikaciju, brzo ćete se vratiti na put
          </p>
          <a href="" class="download-button d-flex gap-4 fw-bold mx-auto mx-lg-0">
            <img src="/icon/download.svg" alt="Download" width="32" height="32">
            Preuzmite odmah
          </a>
        </div>
      </div>
    </div>
  </div>
  <div class="bottom-fade-bg"></div>
  <!--Section End-->
</template>
  
<script>
import LandingPageHader from "@/components/UI/LandingPageHader.vue";
import sanity from "@/client";

const sliderQuery = `*[_type == "sliders"] {
    _id, title, subtitle, text, buttonText, buttonLink,
    "image": mainImage{
          asset->{
          _id,
          url
      },
    },
  }[0]`;

export default {
  data() {
    return {
      loading: true,
      slider: null
    };
  },
  components: {
    LandingPageHader
  },
  computed() {
    return {
    };
  },
  async created() {
    await this.fetchData();
  },
  mounted() {
  },
  methods: {
    fetchData() {
      this.error = this.product = null;
      this.loading = true;

      sanity.fetch(sliderQuery).then(
        (slider) => {
          this.loading = false;
          this.slider = slider;
        },
        (error) => {
          this.error = error;
        }
      );
    },
  }
};
</script>
  
<style>
:root {
  --plyr-video-control-color: #00a651;
  --plyr-control-icon-size: 30px;
  --plyr-video-control-color-hover: #000000cc;
  --plyr-video-control-background-hover: transparent;
}

.header {
  max-width: 1520px;
}

/*--Splash Screen--*/

/*--Tools--*/
.v-line {
  border-left: 1px solid rgb(255, 255, 255, 0.2);
  width: 1px;
  height: 42px;
}

.h-line {
  border-top: 1px solid rgb(255, 255, 255, 0.2);
  width: 100%;
  height: 1px;
}

.h-line-small {
  width: 15px;
  height: 1px;
  border-top: 1px solid var(--second-color);
}

.bg-mobile {
  max-width: 100%;
}

.z-top {
  z-index: 2;
}

.ps-20 {
  padding-left: 20%;
}

.bottom-negative-10 {
  bottom: -10%;
}

.blog-post {
  width: 18.63em;
  height: 10.42em;
}

.first-section .section-title {
  color: #78FC9F;
  font-size: 5rem;
}

@media (max-width: 992px) {
  .first-section .section-title {
    font-size: 2.5rem !important;
  }

  .section-paragraph {
    font-size: 1.5rem !important;
    font-weight: 300;
  }
}

.section-paragraph {
  font-weight: 200;
  font-size: 2rem;
}

.td-none {
  text-decoration: none;
}

.w-lg-50 {
  width: 50%;
}

.w-lg-50 {
  width: 50%;
}

.download-button {
  margin-top: 32px;
  width: fit-content;
  border-radius: 30px;
  padding: 12px 40px 12px 32px;
  font-size: 24px;
  color: #00210C;
  text-decoration: none;
  background: #78FC9F;
  box-shadow: 0px 0px 16px 0px #A0F5C780;
}

.download-button:hover {
  color: #00210C;
}

.bottom-fade-bg {
  height: 4rem;
  background: linear-gradient(180deg, rgb(0, 18, 6) 0%, rgba(120, 252, 159, 0) 100%);
}

.z-100 {
  z-index: 100;
}

/*--Tools--*/

.plyr__control {
  background: white !important;
}

.slideUpText {
  -webkit-animation: slideUpText 500ms ease-in-out;
  /* Chrome, Safari, Opera */
  animation: slideUpText 500ms ease-in-out;
}

@keyframes slideUpText {
  0% {
    opacity: 0;
    transform: translateY(100%) translateZ(0px);
  }

  100% {
    opacity: 1;
    transform: translateY(0%) translateZ(0px);
  }
}

@-webkit-keyframes slideUpText {
  0% {
    opacity: 0;
    transform: translateY(100%) translateZ(0px);
  }

  100% {
    opacity: 1;
    transform: translateY(0%) translateZ(0px);
  }
}

@media (max-width:992px) {
  .size-manager {
    width: auto;
    height: 50vh;
  }

  .w-lg-50 {
    width: 100%;
  }

  .mb-top {
    z-index: 1;
    /* margin-top: 20% !important; */
  }

  .blog-post {
    z-index: 1;
  }

  .video-player {
    display: none;
    opacity: 0;
  }

  #gas-station-prices .w-75 {
    width: 100% !important;
  }
}

#top-section {
  justify-content: space-between;
  gap: 15px;
}

@media (max-width: 992px) {
  #top-section {
    justify-content: start;
    background: linear-gradient(180deg, rgba(0, 18, 6, 0) 33%, #001206 69%);
  }
}

@media (min-height: 700px) and (max-height: 800px) {
  .size-manager {
    right: 70px !important;
  }

  #scrollDownButton {
    right: 20px !important;
  }
}

.my-auto-without-menu {
  margin-top: 150px;
  margin-bottom: auto;
}

.mb-without-menu {
  margin-bottom: 8rem !important;
}
</style>