<template>
  <div class="container py-3 py-lg-5 row w-100">
    <div class="col-lg-1"></div>
    <div
      class="col-lg-10 col fs-body text-main"
      :class="data?.centered ? 'text-center' : 'text-start'"
      :style="{ color: data?.richTextColor?.hex + '!important' }"
    >
      <SanityBlocks v-if="data.body" :blocks="data.body" />
    </div>
  </div>
</template>

<script>
import { SanityBlocks } from "sanity-blocks-vue-component";

export default {
  name: "JobText",

  props: {
    data: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {};
  },

  methods: {},

  components: {
    SanityBlocks,
  },
};
</script>

<style scoped>
@media (max-width: 1024px) {
  .fs-body {
    font-size: 1.25rem !important;
  }
}

.fs-body {
  font-size: 1.5rem;
}
</style>
