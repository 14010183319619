import { createStore } from "vuex";

export default createStore({
  state: {
    splashShown: false,
    menuOpen: false
  },
  mutations: {
    blockSplash(state) {
      state.splashShown = true;
      localStorage.setItem('splashShown', true);
    },
    checkSplash(state) {
      if(localStorage.getItem('splashShown'))
        state.splashShown = localStorage.getItem('splashShown');
      else
        state.splashShown = false;
    },
    openMenu(state) {
      state.menuOpen = true;
    },
    closeMenu(state) {
      state.menuOpen = false;
    }
  },
  actions: {
    blockSplash(context) {
      context.commit('blockSplash');
    },
    checkSplash(context) {
      context.commit('checkSplash');
    },
    openMenu(context) {
      context.commit('openMenu');
    },
    closeMenu(context) {
      context.commit('closeMenu');
    }
  },
  getters: {
    splashShown(state) {
      return state.splashShown;
    },
    menuOpen(state) {
      return state.menuOpen;
    }
  },
  modules: {},
});
