<template>
  <!-- Alternative navigation -->
  <nav class="navbar position-relative navbar-light bg-white navbar-shadow flex-nowrap">
    <div class="container">
        <a class="navbar-brand me-0 p-0" href="/#">
          <img src="../../assets/media/logo.png" alt="Logo" width="62" height="62">
        </a>
        <div class="navbar-middle-content d-lg-block d-none">
            <a class="navbar-brand" href="/"><span class="slideUpText">Početna</span></a>
            <a class="navbar-brand" href="/euro-petrol-klub"><span class="slideUpText">Euro Petrol Klub</span></a>
            <a :class="path == '/benzinske-stanice' ? 'text-main' : ''"  class="navbar-brand" href="/benzinske-stanice"><span class="slideUpText">Lokacije</span></a>
            <a :class="path == '/usluge' ? 'text-main' : ''"  class="navbar-brand" href="/usluge"><span class="slideUpText">Usluge</span></a>
            <a :class="path == '/lion-expert/proizvodi' ? 'text-main' : ''"  class="navbar-brand" href="/lion-expert/proizvodi"><span class="slideUpText">Proizvodi</span></a>
            <a :class="path == '/najnovije-vesti' ? 'text-main' : ''"  class="navbar-brand" href="/najnovije-vesti"><span class="slideUpText">Aktuelnosti</span></a>
        </div>
        <!-- <div class="navbar-middle-content d-lg-block d-none">
          <a class="navbar-brand" href="../#">Početna</a>
          <a class="navbar-brand" href="/#about-euro-petrol">Euro Petrol</a>
          <a class="navbar-brand" href="/#mobile-application">Mobilna aplikacija</a>
          <a class="navbar-brand" href="/#open-positions">EP Klub</a>
          <a class="navbar-brand" href="/#electric-chargers">Električni punjač</a>
          <a class="navbar-brand" href="/#newsletter">Društvene mreže</a>
          <a class="navbar-brand" href="/#blog">Vesti i novosti</a>
        </div> -->

      <a @click="openNavbar" class="btn border text-dark rounded rounded-circle p-4 d-flex align-items-center justify-content-center size-nav-btn-small">
        <i class="ri-menu-3-line fs-4"></i>
      </a>
    </div>

  </nav>
  <!-- Alternative navigation end -->
</template>

<script>
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';

export default {
  setup() {
    const route = useRoute();
    const path = route.path;

    const store = useStore();

    function openNavbar() {
      store.dispatch('openMenu');
    }

    return {
      openNavbar,
      path
    }
  },
}
</script>

<style>
@import url("../../assets/variables.css");

/*--Navbar--*/
.size-nav-btn {
  height: 80px;
  width: 80px;
  transition: all 200ms ease;
  border: 1px solid #D9D9D9;
}

.size-nav-btn-small{
  height: 60px;
  width: 60px;
  transition: all 200ms ease;
  color: #111;
}

.size-nav-btn-small:hover{
  background: rgba(0, 0, 0, 0.281);
  border: none !important;
}

.size-nav-btn:hover{
  background: #00a650a4;
  border: none;
}

.nav-link {
  font-size: 16px;
}

.text-white {
  color: #FFFFFF;
}

.close-navbar {
  animation: navbar-close 0.2s forwards;
}

.open-navbar {
  animation: navbar-open 0.2s forwards;
}
/*--Navbar--*/

/*--Misc--*/
.backdrop-image-dark {
  height: 110vh;
  width: 100vw;
  background: #000;  /*-- fallback for old browsers --*/
  background: radial-gradient(rgb(0,0,0,0.6), rgb(0,0,0)), url("../../assets/media/GasPumpImage1.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  top: 0;
}
/*--Misc--*/

.nav-link:hover{
  color: var(--color-secondary);
}

.nav-link{
  color: white;
  text-decoration: none;
  transition: all 200ms ease-in-out;
}

.text-small{
  font-size: 14px;
}

.text-small:hover{
  transition: width 0.3s ease 0s, left 0.3s ease 0s;
  text-decoration: underline;
}
</style>

<style scoped>

.navbar-shadow{
  box-shadow: 0 26px 64px 0 rgb(20 20 21 / 10%);
}

.navbar-brand{
  font-size: 16px !important;
}

.navbar-brand:hover{
  color: var(--color-main);
}

.active{
  color: var(--color-main);
  font-weight: 700;
}
</style>