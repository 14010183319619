<template>
    <AlternativeHeader />
    <!-- Main container -->
    <section id="privay-policy" class="container py-5">
        <!-- Second paragraph section -->
        <div class="title-section py-5 my-3">
            <h1 class="section-title text-left pb-3">Zaštita podataka</h1>
            <div class="row text-left">
                <div class="col-lg-12">
                    <p class="section-subtitle">
                        Korisnik registrovanjem svojih ličnih podataka u aplikacionoj formi (kroz mobilnu aplikaciju) daje saglasnost da Euro Petrol Doo, u svojstvu Rukovaoca ličnim podacima, prikuplja i obrađuje sledeće podatke: lične podatke navedene u pristupnici, podatke o transakcijama uz identifikaciju karticom na osnovu kojih se ostvaruju pogodnosti programa lojalnosti.
                    </p>

                    <p class="section-subtitle">
                        Saglasnost za obradu podataka daje se dobrovoljno, u svrhu: omogućavanja pogodnosti koje pruža program, kreiranja personalizovanih ponuda na osnovu istorije potrošnje evidentirane putem kartice, kao i u svrhu obaveštavanja o marketinškim uslugama i proizvodima osnivača programa, za sve vreme trajanja članstva u programu. Korisnik u svakom trenutku može opozvati saglasnost. Opoziv saglasnosti ne utiče na dopuštenost obrade koja je vršena na osnovu saglasnosti pre opoziva. Opozivom saglasnosti prestaje dalja obrada podataka, članstvo u programu lojalnosti I mogućnost korišćenja pogodnosti.
                    </p>

                    <p class="section-subtitle">
                        Korisnik u svakom trenutku može opozvati saglasnost. Opoziv saglasnosti ne utiče na dopuštenost obrade koja je vršena na osnovu saglasnosti pre opoziva. Opozivom saglasnosti prestaje dalja obrada podataka, članstvo u programu lojalnosti I mogućnost korišćenja pogodnosti.
                    </p>
                </div>
            </div>
        </div>
        <!-- Second paragraph section end -->
    </section>
</template>

<script>
import AlternativeHeader from "../components/UI/AlternativeHeader.vue";

export default {
  components: {
    AlternativeHeader
  }
}
</script>