import { initializeApp } from 'firebase/app';
import { getDatabase, ref, onValue } from "firebase/database";

const firebaseConfig = {
    apiKey: "AIzaSyAKJ3eBlYaraa4NllU7KZuD9eGrczuK0Xc",
    authDomain: "moonlit-dynamo-107810.firebaseapp.com",
    databaseURL: "https://moonlit-dynamo-107810.firebaseio.com",
    projectId: "moonlit-dynamo-107810",
    storageBucket: "moonlit-dynamo-107810.appspot.com",
    messagingSenderId: "479095110747",
    appId: "1:479095110747:web:508fc5cf929b1075a6137e",
    measurementId: "G-GW0N1PTZMS"
};

const app = initializeApp(firebaseConfig);
const epk_db = getDatabase();

export default epk_db;