<template>
	<!--Navbar Start-->
	<nav class="navbar navbar-expand-lg d-block navbar-dark mt-3 mt-lg-4 position-absolute w-100 mx-auto z-1000">
		<div
			class="container-fluid flex-row navbar-nav d-flex align-items-center justify-content-between px-3 px-lg-5 mb-lg-0">
			<a class="navbar-brand me-0 p-0" href="#">
				<img src="../../assets/media/logo.png" alt="Logo" width="62" height="62">
			</a>
			<ul class="navbar-nav d-none d-lg-flex w-100 justify-content-around align-items-center text-white">
				<li><a href="#" class="nav-link text-second text-decoration-none fw-bold overflow-hidden"><span
							class="slideUpText">Početna</span></a></li>
				<li><a href="/usluge" class="nav-link text-white overflow-hidden"><span class="slideUpText">Usluge</span></a></li>
				<li><a href="/lion-expert/proizvodi" class="nav-link text-white overflow-hidden"><span
							class="slideUpText">Proizvodi</span></a></li>
				<li><a href="/najnovije-vesti" class="nav-link text-white overflow-hidden"><span class="slideUpText">Aktuelnosti</span></a>
				</li>
			</ul>
			<div @click="openNavbar" class="nav-item">
				<button
					class="btn nav-link text-white border rounded rounded-circle p-4 d-flex align-items-center justify-content-center size-nav-btn pulse"
					aria-label="Glavni meni">
					<i class="ri-menu-4-line fs-4"></i>
				</button>
			</div>
		</div>
		<div class="h-line d-none d-lg-block mt-4"></div>
	</nav>
	<!--Navbar End-->
</template>
  
<script>
import { useStore } from 'vuex';

export default {
	setup() {
		const store = useStore();

		function openNavbar() {
			store.dispatch('openMenu');
		}

		function stickyToTop() {
			var top = window.pageYOffset;
			var stickyEl = document.getElementById('onScrollNav');
			try {
				if (top > window.innerHeight) {
					stickyEl.classList.remove('d-none');
				} else {
					stickyEl.classList.add('d-none');
				}
			}
			catch (err) {
				if (err.message == "Uncaught TypeError: stickyEl is null") {
					console.log("");
				}
			}
		}

		document.addEventListener('DOMContentLoaded', function () {
			stickyToTop();
		});

		window.addEventListener('scroll', function () {
			stickyToTop();
		});

		return {
			openNavbar
		}
	},
}
</script>
  
<style>
@import url("../../assets/variables.css");

/*--Navbar--*/
.size-nav-btn {
	height: 80px;
	width: 80px;
	transition: all 200ms ease;
	border: #f8f9fa;
}

.size-nav-btn-small {
	height: 60px;
	width: 60px;
	transition: all 200ms ease;
	color: #111;
}

.size-nav-btn-small:hover {
	background: #00a650 !important;
	border: none !important;
}

.size-nav-btn-small:hover i {
	color: white;
}

.size-nav-btn:hover {
	background: #00a650a4 !important;
	border: none !important;
}

.nav-link {
	font-size: 16px;
}

.text-white {
	color: #FFFFFF;
}

.close-navbar {
	animation: navbar-close 0.2s forwards;
}

.open-navbar {
	animation: navbar-open 0.2s forwards;
}

/*--Navbar--*/

.z-1000 { 
	z-index: 1000;
}

/*--Misc--*/

.pulse {
	animation: pulse-animation 1.6s infinite;
}

.pulse:hover {
	animation: none;
}

@keyframes pulse-animation {
	0% {
		box-shadow: 0 0 0 0px rgba(255, 255, 255, 0.315);
	}

	100% {
		box-shadow: 0 0 0 25px rgba(255, 255, 255, 0);
	}
}

.first-section.backdrop-image-dark {
	height: 90vh;
	width: 100vw;
	background: #000;
	/*-- fallback for old browsers --*/
	background: linear-gradient(rgb(0, 138, 72), rgb(0, 18, 6));
	top: 0;
}

.backgrop-image-dark {
	animation: changeBg 1s infinite;
}

@keyframes changeBg {

	0%,
	100% {
		background: url("https://i.stack.imgur.com/YdrqG.png");
	}

	25% {
		background: url("https://i.stack.imgur.com/2wKWi.png");
	}

	50% {
		background: url("https://i.stack.imgur.com/HobHO.png");
	}

	75% {
		background: url("https://i.stack.imgur.com/3hiHO.png");
	}
}

/*--Misc--*/

.ken-burns {
	animation: move 40s ease;
	-ms-animation: move 40s ease;
	-webkit-animation: move 40s ease;
	-moz-animation: move 40s ease;
	position: absolute;
}

@-webkit-keyframes move {
	0% {
		-webkit-transform-origin: bottom left;
		-moz-transform-origin: bottom left;
		-ms-transform-origin: bottom left;
		-o-transform-origin: bottom left;
		transform-origin: bottom left;
		transform: scale(1);
		-ms-transform: scale(1);
		/* IE 9 */

		-webkit-transform: scale(1);
		/* Safari and Chrome */

		-o-transform: scale(1);
		/* Opera */

		-moz-transform: scale(1);
		/* Firefox */
	}

	100% {
		transform: scale(1.2);
		-ms-transform: scale(1.2);
		/* IE 9 */

		-webkit-transform: scale(1.2);
		/* Safari and Chrome */

		-o-transform: scale(1.2);
		/* Opera */

		-moz-transform: scale(1.2);
		/* Firefox */
	}
}


.nav-link:hover {
	color: var(--color-secondary);
}

.nav-link {
	color: white;
	text-decoration: none;
	transition: all 200ms ease-in-out;
}

.text-small {
	font-size: 14px;
}

.text-small:hover {
	transition: width 0.3s ease 0s, left 0.3s ease 0s;
	text-decoration: underline;
}
</style>
  
<style scoped>
.navbar-shadow {
	box-shadow: 0 26px 64px 0 rgb(20 20 21 / 10%);
}

.navbar-brand {
	font-size: 16px !important;
}

.navbar-brand:hover {
	color: var(--color-main);
}

.active {
	color: var(--color-main);
	background: none;
	font-weight: 700;
}

.b2b-link {
	color: #000;
	text-decoration: none;
}

.b2b-link:hover {
	color: var(--color-main);
}

.focused-b2b:focus {
	color: white;
}</style>