<template>
    <!-- Main container -->
    <section id="about-euro-petrol" class="container py-5">
        <!-- First title section -->
        <div class="title-section text-center d-flex flex-column align-items-center py-5 pb-4">
            <h1 class="section-title">O nama</h1>
            <p class="section-subtitle text-center w-75">
                Euro Petrol je osnovan 1996. godine otvaranjem prve benzinske stanice u Subotici. Od tada, Euro Petrol je postao ugledna kompanija sa distributivnom maloprodajnom mrežom od 24 benzinske stanice na teritoriji Srbije.
            </p>
        </div>
        <!-- First title section end -->

        <!-- Gas Station Toggler -->
        <TimeSwitcher/>
        <!-- Gas Station Toggler End -->

        <!-- Second paragraph section -->
        <div class="title-section py-5 my-3">
            <h1 class="section-title text-left">Prepoznatljiv brend</h1>
            <div class="row text-left">
                <div class="col-lg-7">
                    <p class="section-subtitle">
                        Zahvaljujući jasnoj, doslednoj i stalnoj komunikaciji sa potrošačima predstavljamo sinonim za jedinstven i prepoznatljiv brend u segmentu trgovine naftnim derivatima. Identitet brenda jačamo širenjem svoje maloprodajne mreže uz snažnu marketinšku podršku kroz pažljivo odabran strateški pravac. Svoju diferenciranost Euro Petrol dokazuje kvalitetom goriva na svojim benzinskim stanicama i uslugama koje pruža potrošačima.
                    </p>
                </div>

                <div class="col-lg-5">
                    <p class="section-subtitle">
                        ISO standardi su dokaz da naša kompanija pruža kvalitetnu uslugu. Usaglašenost sa međunarodnim standardima, pored kvaliteta, pruža i garanciju bezbednosti i sigurnosti za potrošače, ali i za naše poslovne partnere.
                    </p>
                </div>
            </div>
        </div>
        <!-- Second paragraph section end -->
    </section>
</template>


<script>
import TimeSwitcher from "../widgets/TimeSwitcher.vue";

export default {
  setup() {},

  created() {},

  components: {
    TimeSwitcher,
  },
};

</script>

<style scoped>

</style>