<template>
    <!-- Electric Chargers Section Start -->
    <section id="electric-chargers" class="d-flex bg-main justify-content-center align-items-center py-2">
        <div class="container py-2">
            <img src="../../assets/media/electric-chargers.jpg" class="img-fluid py-5" alt="Electric Chargers">
            <h1 class="section-title text-light text-left">Električni punjač</h1>

            <div class="row justify-content-between py-5">
                <div class="col-lg-4 d-flex flex-column">
                    <span class="fw-bold mb-2 fs-5">Električni punjač u Subotici</span>
                    <p>
                        Punjač je instaliran na našoj benzinskoj stanici Palić na autoputu E-75 (neposredno po ulasku u Srbiju na graničnom prelazu Horgoš). Ovom inovacijom je omogućeno vlasnicima automobila sa motorima na elektro pogon da koriste utičnicu MENNEKES (Tip 2) sa trofaznim punjačem.
                    </p>
                </div>

                <div class="col-lg-4 d-flex flex-column">
                    <span class="fw-bold mb-3fw-bold mb-2 fs-5">Maksimalna snaga 22kW</span>
                    <p>
                        Elektro punjač na našoj benzinskoj stanici ima maksimalnu snagu 22kW a maksimalna struja je 32A. Punjač je instaliran u saradnji sa kompanijom Ducati komponenti doo koja je postavila preko 5000 punjača po celoj Evropi poštujući sve propisane standarde.
                    </p>
                </div>

                <div class="col-lg-4 d-flex flex-column">
                    <span class="fw-bold mb-2 fs-5">Zaštita životne sredine</span>
                    <p>
                        Prateći trendove u automobilskoj industriji i vodeći računa o zaštiti životne sredine, Euro Petrol se trudi da bude uvek moderna i društveno odgovorna kompanija, a ova instalacija je upravo rezultat tog nastojanja.
                    </p>
                </div>
            </div>
        </div>
    </section>
    <!-- Electric Chargers Section End -->
</template>

<script>
export default {
    setup() {
        
    },
}
</script>

<style scoped>

</style>