<template>
    <HeaderSection/>
    <!-- Blog Section Start -->
    <div class="container my-5">
        <div class="row">
            <span class="fw-bold color-main fs-2 mb-2">Najnovije vesti</span>
        </div>

        <!-- Main - Last Blog Post Start -->
        <div v-for="post in limitedPosts" :key="post._id">
            <div class="row align-items-center" v-if="post.featuredPost">
                <div class="col-lg-8">
                    <img class="img-fluid mw-100" v-if="post.coverImage" :src="imageUrlFor(post.coverImage).width(480)" />
                </div>
                <div class="col-lg-4 d-flex flex-column py-2">
                    <span class="color-main py-1">{{moment(post.publishedAt).locale('sr').format('LL')}}</span>
                    <h2 class="fw-bold">{{post.title}}</h2>
                    <p>{{post.shortDescription}}</p>
                    <router-link class="btn btn-secondary lh-lg rounded-0 d-flex align-items-center justify-content-center gap-1 w-fit-content" :to="`/najnovije-vesti/${post.slug.current}`">
                        Pročitajte više <i class="ri-arrow-right-line color-main"></i>
                    </router-link>
                </div>
            </div>
        </div>
        <!-- Main - Last Blog Post End -->
        <!-- Blog Post Start -->
        <div class="loading" v-if="loading">
            <img src="../assets/blog/spinner.svg" alt=""/>
        </div>

        <div v-if="error" class="error">
            {{error}}
        </div>

        <div class="row my-5 mb-2">
            <div class="col-md-4 p-2" v-for="post in posts" :key="post._id">
                <div class="row g-0 overflow-hidden flex-md-column h-md-250 position-relative">
                    <div class="col-lg-auto">

                        <template v-if="post.image">
                            <router-link :to="`/najnovije-vesti/${post.slug.current}`">
                                <img class="img-fluid blog-img" :src="imageUrlFor(post.image).width(480)" />
                            </router-link>
                        </template>

                        <template v-else>
                            <router-link :to="`/najnovije-vesti/${post.slug.current}`">
                                <img class="img-fluid blog-img" width="480" src="../assets/blog/default-post-img.jpg" />
                            </router-link>
                        </template>

                    </div>
                    <div class="col p-4 d-flex flex-column position-static">
                        <span class="d-inline-block mb-2 color-main">{{moment(post.publishedAt).locale('sr').format('LL')}}</span>
                        <h3 class="fw-bold fs-4">{{post.title}}</h3>
                        <p class="card-text">
                            {{post.shortDescription}}
                        </p>
                        <router-link class="btn btn-secondary lh-lg rounded-0 d-flex align-items-center justify-content-center gap-1 w-fit-content" :to="`/najnovije-vesti/${post.slug.current}`">
                            Pročitajte više <i class="ri-arrow-right-line color-main"></i>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
        <!-- Blog Post End -->
    </div>
    <!-- Blog Section End -->
</template>

<script>
import HeaderSection from "../components/UI/AlternativeHeader.vue";
import sanity from "../client";
import imageUrlBuilder from "@sanity/image-url";
import moment from 'moment';

const imageBuilder = imageUrlBuilder(sanity);

const query = `*[_type == "post"] | order(publishedAt desc){
  _id, title, categories, slug, publishedAt, excerpt, shortDescription, coverImage, featuredPost, body,
  "image": mainImage{
        asset->{
        _id,
        url
    }
  },
}[0...50]`;

export default {
    name: "Blog",
    data() {
        return {
            loading: true,
            posts: [],
            limit: 1, // featured post limiter
        };
    },
    created() {
        this.fetchData();
    },
    methods: {

        moment,

        imageUrlFor(source) {
            return imageBuilder.image(source);
        },

        fetchData() {
            this.error = this.post = null;
            this.loading = true;
            sanity.fetch(query).then(
                (posts) => {
                    this.loading = false;
                    this.posts = posts;
                },
                (error) => {
                    this.error = error;
                }
            );
        },
    },

    computed:{
        limitedPosts(){
            return this.limit ? this.posts.slice(this.limit) : this.posts
        },

        // Add sorting function
    },

    components: {
        HeaderSection,
    },
};

</script>

<style scoped>
.blog-img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.mw-100{
    min-width: 100%;
}
</style>
