<template>
    <HeaderSection />
    <div class="container-fluid d-flex justify-content-center my-5">
      <img class="img-fluid mx-h-300" src="../assets/products/products-header.jpg" alt=""/>
    </div>

    <!-- First title section -->
    <div class="container title-section text-center d-flex flex-column align-items-center mb-4">
        <h1 class="section-title">Naši proizvodi</h1>
        <p class="section-subtitle text-center w-75 mt-2">Pogledajte sve naše proizvode na jednom mestu.</p>
    </div>
    <!-- First title section end -->

    <div class="container mb-5">

        <!-- Product grid -->
        <div class="row my-4" id="generator">
            <div class="col-lg-4 p-3" v-for="product in products" :key="product._id">
                <div class="card d-flex align-items-center py-5 position-relative">
                    <div class="d-flex flex-column align-items-center product-info">
                        <img v-if="product.image" :src="imageUrlFor(product.image)" class="img-fluid hw-250" alt="EDT Lion Dynamic">
                        <span class="product-title fw-bold text-capitalize">{{product.title}}</span>
                        <p>{{product.shortDescription}}</p>
                        <!-- <img src="../assets/products/360-icon.svg" class="position-absolute top-right" alt="360 Degree View"> -->
                    </div>
                    <router-link :to="`/lion-expert/proizvodi/${product.slug.current}`" class="btn btn-main product-btn d-flex gap-2 lh-lg rounded-0 text-white" type="submit"><i class="ri-eye-line"></i> Pogledaj proizvod</router-link>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import HeaderSection from "../components/UI/AlternativeHeader.vue";
import sanity from "../client";
import imageUrlBuilder from "@sanity/image-url";
import moment from 'moment';

const imageBuilder = imageUrlBuilder(sanity);

const query = `*[_type == "products"] | order(title desc){
  _id, title, categories, slug, createdAt, description, shortDescription,
  "image": mainImage{
        asset->{
        _id,
        url
    },
  },
}[0...50]`;

export default {
    name: "Blog",
    data() {
        return {
            loading: true,
            products: [],
        };
    },
    created() {
        this.fetchData();
    },
    methods: {

        moment,

        imageUrlFor(source) {
            return imageBuilder.image(source);
        },

        fetchData() {
            this.error = this.product = null;
            this.loading = true;
            sanity.fetch(query).then(
                (products) => {
                    this.loading = false;
                    this.products = products;
                },
                (error) => {
                    this.error = error;
                }
            );
        },
    },

    computed:{
    },

    components: {
        HeaderSection,
    },
};

</script>

<style scoped>
a{
    color: inherit;
    text-decoration: none;
}

.categories-gap{
    gap: 3rem;
}

.bg-main i, .border:hover i{
    color: var(--color-secondary);
}

.border:hover{
    background-color: var(--color-main);
    color: white;
}

.hw-250{
    height: 250px;
    width: auto;
}

.product-title{
    font-weight: bold;
    font-size: 30px;
    text-align: center;
}

.top-right{
    top: 25px;
    right: 25px;
}

.product-btn{
    opacity: 0;
    display: none;
    position: absolute;
    top: 50%;
}

.card:hover .product-info{
    -webkit-filter: blur(4px);
    filter: blur(4px);
}

.card:hover .product-btn{
    opacity: 1 !important;
    display: flex !important;
}

@media (max-width: 576px) {
  .categories-gap{
    gap: 0.5rem;
  }
}
</style>