<template>
<div class="cookie-consent-container">
  <div class="cookie-consent text-uppercase" v-if="showCookieDialog">
    <p id="cookie-consent-description" class="cookie-consent-text">
        Naš sajt koristi kolačiće kako bi smo poboljšali Vaše korisničko iskustvo.
        <br/><a aria-label="Saznaj više o kolačičima" tabindex="0" href="/pravila-i-uslovi-koriscenja" class="cookie-consent-link">Saznaj više <i class="ri-arrow-right-up-line"></i></a>
    </p>
    <button aria-label="Ok" class="cookie-consent-btn px-3 text-uppercase" v-on:click="enable(), hideCookie()" id="accept">Ok</button>
  </div>
</div>
  <OpenedMenu v-if="opened" />
  <SplashScreen v-if="!shown" />
    <router-view />
  <FooterSection />
</template>

<script>
import { useStore } from 'vuex';
import { computed, onMounted, watch } from '@vue/runtime-core';
import FooterSection from "./components/UI/FooterSection.vue";
import SplashScreen from './components/UI/SplashScreen.vue';
import OpenedMenu from './components/UI/OpenedMenu.vue';
import { useState, setOptions } from "vue-gtag-next";  

export default {
  name: "Cookie",
  data(){
    return{
      showCookieDialog: true,
    };
  },
  components: {
    SplashScreen,
    FooterSection,
    OpenedMenu
  },

  setup() {
    const { isEnabled } = useState();
    const store = useStore();

    const enable = () => {  
      isEnabled.value = true;  
      document.cookie = "euro-petrol.com=true; max-age=31536000; SameSite=Lax";
    };  

    const shown = computed(() => {
      return store.getters.splashShown;
    })

    const opened = computed(() => {
      return store.getters.menuOpen;
    })

    watch(opened, () => {
      if(opened.value)
        document.body.classList.add('overflow-hidden');
      else
        document.body.classList.remove('overflow-hidden');
    })

    onMounted(() => {
      store.dispatch('checkSplash');
    })

    return {
      shown,
      opened,
      enable,
    }
  },

  created(){
    if (document.cookie.split(";").some((item) => item.trim().startsWith("euro-petrol.com="))){
      this.hideCookie();
    }
  },

  methods: {
    hideCookie(){
      this.showCookieDialog = false;
    },
  },

  mounted(){}
};

</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

@import "./assets/variables.css";


*::-moz-selection { color: white;  background: #028140; }  /* For Firefox */
*::selection      { color: white;  background: #028140; }  /* Other ::selection-compatible browsers */

body{
  cursor: default !important;
}

h1 {
  font-family: "Inter";
  font-size: 5rem;
  font-style: normal;
  font-weight: 800;
  color: #fff;
}

h2 {
  font-family: "Inter";
  font-size: 40px;
  font-weight: 400;
  text-align: left;
}

h4 {
  font-family: "Inter";
  font-size: 25px;
  font-weight: 600;
  text-align: left;
}

h5 {
  font-family: "Inter";
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  text-align: center;
  color: #fff;
}

.text-justify {
  text-align: justify;
}

.text-gray {
  color: #F2F2F2;
}

.text-black {
  color: var(--color-dark) !important;
}

.bg-whiteTransparent {
  background: rgba(255, 255, 255, 0.95);
}

.btn-check:focus, .btn:focus{
  box-shadow: 0 0 0 0.25rem #00a65034;
}

.swiper-slide-shadow-left, .swiper-slide-shadow-right{
    background-image: none !important;
}

.def-border-1{
  border: 1px solid #E7E7E7 !important;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

.mx-h-300 {
  min-height: 300px;
  object-fit: cover;
}

.header-overlay-search__input {
    position: relative;
    border: none;
    border-radius: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    display: block;
    width: 100%;
    background: transparent;
}

.header-overlay-search__input:focus{
  outline: 0;
}

.bg-white {
  background-color: white;
}

.btn-main {
  background-color: var(--color-main);
  color: white;
}

.btn-main:hover, .btn-secondary:hover{
  color: #eee;
  background-color: var(--color-main-hover) !important;
  border: 1px solid var(--color-main-hover);
}

.btn-secondary:hover i{
    color: #eee;
}

.btn-secondary{
  background-color: transparent;
  border: 1px solid rgba(17, 17, 17, 0.200);
  color: inherit;
}

.bg-main {
  background-color: var(--color-main);
  color: #fff;
}

.border-muted {
  border-color: #E7E7E7;
}

.objFit {
  object-fit: cover;
}

.w-content {
  width: fit-content;
}

.size-arrow {
  width: 15px;
  height: 15px;
}

#app {
  font-family: "Inter", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #333;
  overflow-x: hidden;
}

.color-main{
    color: var(--color-main);
}

.color-secondary{
    color: var(--color-secondary);
}

.card{
  border-radius: 0;
}

.fs-180{
  font-size: 180px;
}

.zi-top{
  z-index: 1;
}

.bg-main{
    background-color: var(--color-main);
}

.bg-open-menu {
  background: linear-gradient(var(--color-main-transparent), var(--color-main-transparent)) ,url('./assets/media/GasPumpImage1.jpg');
  background-position: center;
  background-repeat: no-repeat;
}

::-webkit-scrollbar-thumb {
    background: var(--color-main-hover);
}

::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    background: rgb(230, 230, 230);
}

@media (max-width: 576px) {
  h1 {
    font-size: 3rem !important;
  }

  h2 {
    font-size: 30px;
  }

  h4 {
    font-size: 18px;
  }

  h5 {
    font-size: 0.8rem;
  }
  
  body {
    font-size: 16px;
  }

  .size-arrow {
    width: 8px;
    height: 8px;
  }
}

.cookie-consent{
  width: 21vw;
  background-color: rgba(0, 0, 0, 0.541);
  margin: 0 auto;
  border-radius: 70px;
  bottom: 20px;
  display: flex;
  padding: 30px;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  z-index: 1000;
  margin: 0 auto;
  color: white;
  backdrop-filter: blur(15px);
}

.cookie-consent-text, .cookie-consent-btn{
  font-size: 13px;
  margin: 0;
}

.cookie-consent-btn{
  background-color: transparent;
  border: 1px solid var(--color-main);
  color: inherit;
  padding: 5px;
  transition: all 0.3s ease 0s;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
}

.cookie-consent-container{
  display: flex;
  padding: 0 25px;
  justify-content: end;
}

.cookie-consent-btn:hover{
  background-color: var(--color-main);
}

.cookie-consent-link{
  color: var(--color-secondary);
  text-decoration: none;
}

.cookie-consent-link:hover{
  color: var(--color-secondary-hover);
  text-decoration: underline;
}

/* Chrome, Safari, Opera */
@-webkit-keyframes fadeInUp {
    0% {
        opacity: 0;
        -webkit-transform: translateY(80px);
    }
    100% {
        opacity: 1;
        -webkit-transform: translateY(0);
    }
}

/* Standard syntax */
@keyframes fadeInUp {
    0% {
        opacity: 0;
        transform: translateY(80px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@media (max-width: 1200px) {
  .cookie-consent{
    width: 90%;
  }
  .cookie-consent-container{
    padding: 0;
    justify-content: center;
  }
}

@media (max-width: 475px) {
  .cookie-consent{
    width: 90%;
    margin: 0;
  }
}

.fp-menu-link-text span::after {
    content: attr(data-text);
    display: block;
    position: absolute;
    top: 100%;
    left: 0;
    -webkit-transform-origin: left top;
    -moz-transform-origin: left top;
    -ms-transform-origin: left top;
    -o-transform-origin: left top;
    transform-origin: left top;
    -webkit-transition: -webkit-transform .9s cubic-bezier(.19,1,.22,1);
    transition: -webkit-transform .9s cubic-bezier(.19,1,.22,1);
    -o-transition: -o-transform .9s cubic-bezier(.19,1,.22,1);
    -moz-transition: transform .9s cubic-bezier(.19,1,.22,1),-moz-transform .9s cubic-bezier(.19,1,.22,1);
    transition: transform .9s cubic-bezier(.19,1,.22,1);
    transition: transform .9s cubic-bezier(.19,1,.22,1),-webkit-transform .9s cubic-bezier(.19,1,.22,1),-moz-transform .9s cubic-bezier(.19,1,.22,1),-o-transform .9s cubic-bezier(.19,1,.22,1);
}

:after, :before {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}
</style>
