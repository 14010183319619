<template>
  <div id="splash" class="position-fixed bg-open-menu vh-100 vw-100 z-top overflow-hidden d-flex align-items-center justify-content-center">
    <img src="../../assets/media/logo.png" alt="Logo" width="62" height="62">
  </div>
</template>

<script>
import { useStore } from 'vuex';
import { onMounted } from '@vue/runtime-core'
export default {
  setup() {
    const store = useStore();
    onMounted(() => {
      document.querySelector("#splash").addEventListener('animationend', () => {
          store.dispatch('blockSplash');
      });
    })
  },
}
</script>

<style scoped>
  @keyframes slideLeft {
    0% {
      transform: translateY(0%) translateZ(0px);
    }
    100% {
      transform: translateY(-100%) translateZ(0px);
      visibility: visible;
    }
  }

  @-webkit-keyframes slideUpText{
    0%{
      opacity: 0;
      transform: translateY(100%) translateZ(0px);
    }
    100%{
      opacity: 1;
      transform: translateY(0%) translateZ(0px);
    }
  }

  #splash {
    animation: slideLeft 0.6s 1s ease-in forwards;
    z-index: 9998;
    overflow: hidden;
  }
</style>