<template>

    <HeaderSection />
    <div class="container-fluid d-flex justify-content-center my-5">
      <img class="img-fluid mx-h-80" src="../assets/lion-expert/le-main-opt-2.jpg" alt=""/>
    </div>

    <div class="container">
        <!-- Lion Expert Items -->
        <div class="d-flex gap-3 my-5">
            <div class="job-item w-100">
                <div class="d-flex flex-column align-items-left flex-wrap">
                    <div class="d-flex flex-column gap-2">
                        <h2 class="color-main block-title">
                            Lion Expert gorivo
                            </h2>
                        <div class="le-item-details">
                            <p class="mt-2 mw-47rem mb-1">
                                Lion Expert je vrhunsko goriva, sa dodatkom specijalne formule aditiva renomiranog nemačkog proizvođača, koji je posebno dizajniran da poboljša performanse i efikasnost vašeg vozila.
                            </p>
                        </div>
                    </div>
                    <div class="d-flex">
                        <a href="/lion-expert/gorivo" class="btn btn-secondary lh-lg rounded-0 d-flex align-items-center justify-content-between gap-1 w-sm-100 px-4" type="submit">Saznaj više<i class="ri-arrow-right-line  block-title"></i></a>
                    </div>
                </div>
            </div>

            <div class="job-item w-100">
                <div class="d-flex flex-column align-items-left flex-wrap">
                    <div class="d-flex flex-column">
                        <h2 class="color-main block-title">
                            Lion Expert proizvodi
                            </h2>
                        <div class="le-item-details">
                            <p class="mt-2 mw-47rem mb-1">
                                Pogledajte sve naše proizvode na jednom mestu.
                            </p>
                        </div>
                    </div>
                    <div class="d-flex">
                        <a href="/lion-expert/proizvodi" class="btn btn-secondary lh-lg rounded-0 d-flex align-items-center justify-content-between gap-1 w-sm-100 px-4" type="submit">Saznaj više<i class="ri-arrow-right-line  block-title"></i></a>
                    </div>
                </div>
            </div>
        <!-- Lion Expert Items End -->
        </div>
    </div>

</template>


<script>
import HeaderSection from "../components/UI/AlternativeHeader.vue";
import sanity from "../client";
import imageUrlBuilder from "@sanity/image-url";
import moment from 'moment';

const imageBuilder = imageUrlBuilder(sanity);

const query = `*[_type == "products"] | order(title desc){
  _id, title, categories, slug, createdAt, description, shortDescription,
  "image": mainImage{
        asset->{
        _id,
        url
    },
  },
}[0...50]`;

export default {
    name: "Blog",
    data() {
        return {
            loading: true,
            products: [],
        };
    },
    created() {
        this.fetchData();
    },
    methods: {

        moment,

        imageUrlFor(source) {
            return imageBuilder.image(source);
        },

        fetchData() {
            this.error = this.product = null;
            this.loading = true;
            sanity.fetch(query).then(
                (products) => {
                    this.loading = false;
                    this.products = products;
                },
                (error) => {
                    this.error = error;
                }
            );
        },
    },

    computed:{
    },

    components: {
        HeaderSection,
    },
};

</script>

<style scoped>
a{
    color: inherit;
    text-decoration: none;
}

.categories-gap{
    gap: 3rem;
}

.bg-main i, .border:hover i{
    color: var(--color-secondary);
}

.border:hover{
    background-color: var(--color-main);
    color: white;
}

.hw-250{
    height: 250px;
    width: auto;
}

.product-title{
    font-weight: bold;
    font-size: 30px;
    text-align: center;
}

.top-right{
    top: 25px;
    right: 25px;
}

.product-btn{
    opacity: 0;
    display: none;
    position: absolute;
    top: 50%;
}

.card:hover .product-info{
    -webkit-filter: blur(4px);
    filter: blur(4px);
}

.card:hover .product-btn{
    opacity: 1 !important;
    display: flex !important;
}

@media (max-width: 576px) {
  .categories-gap{
    gap: 0.5rem;
  }
}

.fuel-section-title {
    margin: 40px 0 0 0 !important;
    color: #00a651;
    font-size: 4.17em;
    font-weight: 800;
    cursor: default;
}

.le-item-details {
    margin-top: 15px;
    min-height: 120px;
}

.mx-h-80 {
    max-height: 80vh;
}

.block-title {
    font-weight: bold;
}

.job-item {
    padding: 2.6em;
    border: 1px solid #e0e0e0;
}
</style>