<template>
    <!-- Blog Section Start -->
    <section id="blog" class="py-5">
        <div class="container py-5">
            <div class="blog-heading pb-3">
                <p class="section-subtitle my-0">Saznajte više o aktuelnim novostima.</p>
                <h1 class="section-title color-main">Najnovije vesti</h1>
                <!-- Blog list -->
            </div>

            <div class="row gap-3 blog-list pb-5">
                    <!-- Blog Item -->
                    <swiper :grabCursor="true" @swiper="onSwiper" :pagination='{ "clickable": true }' @slideChange="onSlideChange" :autoplay='{ "delay": 2500 }'
                    :breakpoints='{
                      "640": {
                        "slidesPerView": 1,
                        "spaceBetween": 20
                      },
                      "768": {
                        "slidesPerView": 2,
                        "spaceBetween": 20
                      },
                      "1100": {
                        "slidesPerView": 2,
                        "spaceBetween": 20
                      },
                      "1400": {
                        "slidesPerView": 3,
                        "spaceBetween": 20
                      }
                    }'>
                      <swiper-slide class="slide-fade-in" v-for="post in posts" :key="post._id">
                        <router-link :to="`/najnovije-vesti/${post.slug.current}`" class="col-lg blog radius-0 p-3 fw-bold text-light" :aria-label="post.title"  v-bind:style="{ 'background-image': 'url(' + imageUrlFor(post.image) + ')' }"> <span class="blog-text">{{post.title}} <i class="ri-arrow-right-line color-secondary"></i></span></router-link>
                        <router-link :to="`/najnovije-vesti/${post.slug.current}`" :aria-label="post.title" style="width: 100%; height: 100%;"></router-link>
                      </swiper-slide>

                    </swiper>
                      
            </div>
        </div>
    </section>
    <!-- Blog Section End -->
</template>
<script>
  // Import Swiper Vue.js components
  import { Swiper, SwiperSlide } from 'swiper/vue/swiper-vue.js';
  import SwiperCore, { Pagination } from 'swiper';

  // install Swiper modules
  SwiperCore.use([Pagination]);

  // Import Swiper styles
  import "swiper/swiper-bundle.css";
  import sanity from "../../client";
  import imageUrlBuilder from "@sanity/image-url";
  import moment from 'moment';

  const imageBuilder = imageUrlBuilder(sanity);

  const query = `*[_type == "post"] | order(publishedAt desc){
    _id, title, categories, slug, publishedAt, excerpt, shortDescription, featuredPost, body,
    "image": mainImage{
          asset->{
          _id,
          url
      }
    },
  }[0...6]`;

  export default {
    components: {
      Swiper,
      SwiperSlide,
    },
    data() {
        return {
          loading: true,
          posts: [],
        };
    },
    created() {
        this.fetchData();
    },
    methods: {

        moment,

        imageUrlFor(source) {
            return imageBuilder.image(source);
        },

        fetchData() {
            this.error = this.post = null;
            this.loading = true;
            sanity.fetch(query).then(
                (posts) => {
                    this.loading = false;
                    this.posts = posts;
                },
                (error) => {
                    this.error = error;
                }
            );
        },
    },
    setup() {
      const onSwiper = (swiper) => {
        return swiper;
      };
      const onSlideChange = () => {
      };
      return {
        onSwiper,
        onSlideChange,
      };
    },
  };
</script>

<style scoped>
.radius-0{
    border-radius: 0;
}

.blog{
    height: 450px;
    background-color: var(--color-main);
    background-position: 50% 50% !important;
    background-size: cover !important;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    transition: all 0.4s ease-in-out;
    text-decoration: none;
}

.blog:hover{
  background-image: linear-gradient(var(--color-secondary), var(--color-main)) !important;
}

.blog-text{
  opacity: 0;
  visibility: hidden;
  display: flex;
  width: 100%;
}

.blog:hover .blog-text{
  opacity: 100%;
  visibility: visible;
}

.slide-fade-in:hover{
  -webkit-animation: slideFade 500ms ease-in-out; /* Chrome, Safari, Opera */
  animation: slideFade 500ms ease-in-out;
}


@media (min-width: 1200px) and (max-width: 1400px) { 
  .blog{
    height: 550px;
  }  
}

@media (min-width: 767px) and (max-width: 990px) {
    .blog{
      height: 350px;
    }
}

@media (min-width: 401px) and (max-width: 766px) {
    .blog{
      height: 500px;
    }
}

@media (max-width: 400px){
  .blog{
    height: 400px;
  }
}


/* Chrome, Safari, Opera */
@-webkit-keyframes slideFade {
    0% {
        opacity: 0.5;
    }
    100% {
        opacity: 1;
    }
}

/* Standard syntax */
@keyframes slideFade {
    0% {
        opacity: 0.2;
    }
    100% {
        opacity: 1;
    }
}
</style>