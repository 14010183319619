<template>
    <HeaderSection />
    <div class="container-fluid d-flex justify-content-center my-5">
      <img class="img-fluid mx-h-300" src="../assets/lion-expert/le-gorivo-main.png" alt=""/>
    </div>


    <!-- First section -->
    <section class="py-2">
        <div class="container title-section text-center d-flex flex-column align-items-center mb-5">
            <h1 class="main-section-title">Predstavljamo Lion Expert - vrhunsko aditivirano gorivo kompanije Euro Petrola</h1>
        </div>

        <div class="container my-5">
            <div class="text-content">
                <p class="section-text">
                    Euro Petrol je domaća, porodična kompanija, koja se na tržištu pozicionirala kao dobavljač visokokvalitetnih naftnih proizvoda, kojima kupci veruju. Jedna od naših Premium ponuda jeste Lion Expert, naše vrhunsko aditivno gorivo koje je dizajnirano da pruži izuzetne performanse i efikasnost. U ovom članku ćemo razgovarati o tome zašto vredit koristiti Lion Expert, šta čini Lion Ekpert formulu, kako funkcioniše i koje su prednosti u korišćenju ovog Premium goriva.
                </p>
            </div>
        </div>
    </section>
    <!-- First section end -->


    <!-- Second section -->
    <section class="d-flex bg-main justify-content-center py-2 mt-5 paired-section">
        <div class="container my-5">

            <!-- Header Image Start -->
            <div class="container-fluid d-flex justify-content-center my-5">
                <img class="img-fluid mx-h-300" src="../assets/lion-expert/sec2-opt.jpg" alt="kasir-header" />
            </div>
            <!-- Header Image End -->

            <div class="container title-section text-center d-flex flex-column align-items-center mb-5">
                <h1 class="paired-section-title">Zašto je vredi koristiti Lion Expert?</h1>
            </div>

            <div class="text-content mb-5">
                <p class="section-text">
                    Lion Expert je vrhunsko goriva, sa dodatkom specijalne formule aditiva renomiranog nemačkog proizvođača, koji je posebno dizajniran da poboljša performanse i efikasnost vašeg vozila. Kada koristite Lion Expert, možete očekivati brojne prednosti, uključujući:
                </p>
            </div>

            <div class="d-flex flex-column gap-2">
                <div>
                    <span class="fw-bold mb-4 fs-5">Poboljšana ušteda goriva:</span>
                    <p>Lion Expert sadrži posebne aditive koji pomažu u poboljšanju potrošnje goriva. To znači da možete više da vozite sa jednim rezervoarom goriva, štedeći novac na duge staze.</p>
                </div>
                <div>
                    <span class="fw-bold mb-4 fs-5">Povećana snaga:</span>
                    <p>Formula Lion Expert-a takođe pomaže da se poboljšaju performanse motora, dajući vašem vozilu više snage i obrtnog momenta. To znači da možete brže ubrzati i lakše se nositi sa strmim nagibima i teškim teretima.</p>
                </div>
                <div>
                    <span class="fw-bold mb-4 fs-5">Cleaner Engine:</span>
                    <p>Specijalni aditivi u Lion Expertu pomažu u čišćenju vašeg motora uklanjajući naslage i sprečavajući nakupljanje štetnih masnoća. Ovo dovodi do dužeg veka trajanja motora i manje troškova održavanja tokom vremena.</p>
                </div>
            </div>
        </div>
    </section>
    <!-- Second section end -->


    <!-- Third section -->
    <section class="d-flex justify-content-center py-2 mt-5">
        <div class="container my-5">

            <!-- Header Image Start -->
            <div class="container-fluid d-flex justify-content-center my-5">
                <img class="img-fluid mx-h-300" src="../assets/lion-expert/sec3-opt.jpg" alt="kasir-header" />
            </div>
            <!-- Header Image End -->

            <div class="container title-section text-center d-flex flex-column align-items-center mb-5">
                <h1 class="section-title">Šta je formula stručnjaka za lavove?</h1>
            </div>

            <div class="text-content mb-5">
                <p class="section-text">
                    Formula Lion Expert je jedinstvena mešavina aditiva vrhunskog kvaliteta koji su posebno dizajnirani da poboljšaju performanse i efikasnost vašeg vozila. Neki od ključnih aditiva u formuli uključuju:
                </p>
            </div>

            <div class="d-flex flex-column gap-2">
                <div>
                    <span class="fw-bold mb-2 fs-5 color-main" data-v-95c385a8="">Deterdženti:</span>
                    <p>Oni pomažu u čišćenju vašeg motora uklanjanjem štetnih naslaga i sprečavanjem stvaranja novih.</p>
                </div>
                <div>
                    <span class="fw-bold mb-2 fs-5 color-main" data-v-95c385a8="">Inhibitori korozije:</span>
                    <p>Oni štite vaš motor od korozije, što može dovesti do skupih popravki i smanjenih performansi.</p>
                </div>
                <div>
                    <span class="fw-bold mb-2 fs-5 color-main" data-v-95c385a8="">Pojačivači oktana:</span>
                    <p>Ovi povećavaju oktanski broj vašeg goriva, što može poboljšati performanse motora i smanjiti kucanje motora.</p>
                </div>
                <div>
                    <span class="fw-bold mb-2 fs-5 color-main" data-v-95c385a8="">Maziva:</span>
                    <p>Oni pomažu da se smanji trenje između delova motora, što dovodi do manjeg habanja i dužeg veka motora.</p>
                </div>
            </div>
        </div>
    </section>
    <!-- Third section end -->


    <!-- Fourth section -->
    <section class="d-flex bg-main justify-content-center py-2 mt-5 paired-section">
        <div class="container my-5" data-v-2d98e0c4="">

            <!-- Header Image Start -->
            <div class="container-fluid d-flex justify-content-center my-5">
                <img class="img-fluid mx-h-300" src="../assets/lion-expert/sec4-opt.jpg" alt="kasir-header" />
            </div>
            <!-- Header Image End -->

            <div class="container title-section text-center d-flex flex-column align-items-center mb-5">
                <h1 class="paired-section-title">Kako funkcioniše formula Lion Expert?</h1>
            </div>

            <div class="text-content" data-v-2d98e0c4="">
                <p class="section-text" data-v-2d98e0c4="">
                    Lion Expert formula funkcioniše tako što poboljšava proces sagorevanja u vašem motoru. Specijalni aditivi u formuli pomažu da se gorivo efikasnije razgradi, što dovodi do efikasnijeg procesa sagorevanja. Ovo, zauzvrat, dovodi do bolje uštede goriva, povećane snage i čistijeg motora.
                </p>
            </div>
        </div>
    </section>
    <!-- Fourth section end -->


    <!-- Fifth section -->
    <section class="d-flex justify-content-center py-2 mt-5">
        <div class="container my-5" data-v-2d98e0c4="">

            <!-- Header Image Start -->
            <div class="container-fluid d-flex justify-content-center my-5">
                <img class="img-fluid mx-h-300" src="../assets/lion-expert/sec5-opt.jpg" alt="kasir-header" />
            </div>
            <!-- Header Image End -->

            <div class="container title-section text-center d-flex flex-column align-items-center mb-5">
                <h1 class="section-title">Prednosti Lion Expert goriva</h1>
            </div>

            <div class="dflex flex-column gap-2 mb-5">
                <div>
                    <span class="fw-bold mb-2 fs-5 color-main" data-v-95c385a8="">Bolja ekonomičnost goriva:</span>
                    <p>Lion Expert gorivo može vam pomoći da uštedite novac, poboljšavajući efikasnost goriva.</p>
                </div>
                <div>
                    <span class="fw-bold mb-2 fs-5 color-main" data-v-95c385a8="">Povećana snaga:</span>
                    <p>Lion Expert gorivo može pomoći u poboljšanju performansi vašeg motora, dajući vam više snage i obrtnog momenta.</p>
                </div>
                <div>
                    <span class="fw-bold mb-2 fs-5 color-main" data-v-95c385a8="">Duži vek motora:</span>
                    <p>Lion Expert gorivo pomaže u čišćenju vašeg motora i smanjenju nakupljanja štetnih naslaga, što može dovesti do dužeg veka motora.</p>
                </div>
                <div>
                    <span class="fw-bold mb-2 fs-5 color-main" data-v-95c385a8="">Smanjeni troškovi održavanja:</span>
                    <p>Lion Expert gorivo može pomoći u smanjenju troškova održavanja, sprečavanjem nagomilavanja štetnih naslaga i smanjenjem habanja delova motora.</p>
                </div>

            </div>
            <div class="text-content" data-v-2d98e0c4="">
                <p class="section-text" data-v-2d98e0c4="">
                    U zaključku, Lion Expert je vrhunsko gorivo sa dodatkom Premium nemačkog aditiva, koje je posebno dizajnirano da poboljša performanse i efikasnost vašeg vozila. Njegova jedinstvena formula sadrži posebne aditive koji pomažu u poboljšanju potrošnje goriva, povećanju snage i smanjenju troškova održavanja. Ako želite da sami iskusite prednosti Lion Experta, obavezno ga isprobajte danas!
                </p>
            </div>
        </div>
    </section>
    <!-- Fourth section end -->

</template>


<script>
import HeaderSection from "../components/UI/AlternativeHeader.vue";
import sanity from "../client";
import imageUrlBuilder from "@sanity/image-url";
import moment from 'moment';

const imageBuilder = imageUrlBuilder(sanity);

const query = `*[_type == "products"] | order(title desc){
  _id, title, categories, slug, createdAt, description, shortDescription,
  "image": mainImage{
        asset->{
        _id,
        url
    },
  },
}[0...50]`;

export default {
    name: "Blog",
    data() {
        return {
            loading: true,
            products: [],
        };
    },
    created() {
        this.fetchData();
    },
    methods: {

        moment,

        imageUrlFor(source) {
            return imageBuilder.image(source);
        },

        fetchData() {
            this.error = this.product = null;
            this.loading = true;
            sanity.fetch(query).then(
                (products) => {
                    this.loading = false;
                    this.products = products;
                },
                (error) => {
                    this.error = error;
                }
            );
        },
    },

    computed:{
    },

    components: {
        HeaderSection,
    },
};

</script>

<style scoped>
a{
    color: inherit;
    text-decoration: none;
}

.categories-gap{
    gap: 3rem;
}

.bg-main i, .border:hover i{
    color: var(--color-secondary);
}

.border:hover{
    background-color: var(--color-main);
    color: white;
}

.hw-250{
    height: 250px;
    width: auto;
}

.product-title{
    font-weight: bold;
    font-size: 30px;
    text-align: center;
}

.top-right{
    top: 25px;
    right: 25px;
}

.product-btn{
    opacity: 0;
    display: none;
    position: absolute;
    top: 50%;
}

.card:hover .product-info{
    -webkit-filter: blur(4px);
    filter: blur(4px);
}

.card:hover .product-btn{
    opacity: 1 !important;
    display: flex !important;
}

@media (max-width: 576px) {
  .categories-gap{
    gap: 0.5rem;
  }
}

.main-section-title {
    margin: 40px 0 0 0 !important;
    color: #00a651;
    font-size: 3.438rem;
    font-weight: 800;
    cursor: default;
}

.paired-section {
    color: white !important;
}

.section-title {
    margin: 0 !important;
    color: #00a651;
    font-size: 3rem;
    font-weight: 800;
    cursor: default;
}

.paired-section-title {
    margin: 0 !important;
    color: white;
    font-size: 3rem;
    font-weight: 800;
    cursor: default;
}

.section-text {
    margin-top: 1.04em;
    margin-bottom: 1.04em;
    padding-top: 0px;
    padding-bottom: 0px;
    font-size: 1.04em;
    line-height: 1.5em;
    font-weight: 500;
    cursor: default;
}
</style>