<template>
  <div class="section-container d-flex justify-content-center">
    <div class="section-content d-flex flex-column gap-2 align-items-center justify-content-center position-relative">
      <div class="img-container"><img src="/icon/pricetag.png" alt="Price Tag Icon" /></div>
      <div class="content d-flex flex-column gap-1">
        <span class="title">Sa lakoćom iskoristite naše brojne popuste</span>
        <span class="text">Prebacite se na naš sistem lojalnosti i uživajte u mnogim pogodnostima putem
          naše aplikacije</span>
      </div>
    </div>
    <div class="section-bg-bottom" />
    <div class="bg-icon1" />
    <div class="bg-icon2" />
  </div>
</template>

<script></script>

<style scoped>
.section-container {
  overflow: hidden;
  padding: 80px 1rem 8rem 1rem;
  background-color: #005f30;
  position: relative;
}

.section-bg-bottom {
  position: absolute;
  background-image: url("/img/bg-bottom.png");
  width: 100%;
  height: 100%;
  display: flex;
  /* background-position: -1700px bottom; */
  background-repeat: no-repeat;
  background-size: cover;
  left: 0;
  z-index: 1;
  bottom: 0;
  transform: translateY(50%);
  pointer-events: none;

}

.section-container i {
  font-size: 80px;
  color: white;
}

.section-content {
  z-index: 2;
  max-width: 1330px;
}

.section-content img {
  aspect-ratio: 1/1;
  width: 80px;
}

.section-content .title {
  font-size: 2rem;
  line-height: 38px;
  color: white;
  display: flex;
  text-align: center;
  align-self: center;
  font-weight: 700;
}

.section-content .text {
  font-size: 1.5rem;
  line-height: 28px;
  text-align: center;
  color: #eeeeee;
}

.bg-icon1 {
  background-image: url("/icon/bg-icon1.png");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  aspect-ratio: 1/1;
  width: 166px;
  position: absolute;
  top: 25%;
  left: 0;
  z-index: 0;
  transform: translateX(-45%);
  pointer-events: none;
}

.bg-icon2 {
  background-image: url("/icon/bg-icon2.png");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  aspect-ratio: 1/1;
  width: 120px;
  position: absolute;
  top: 5px;
  right: 0;
  z-index: 0;
  transform: translateX(25%);
  pointer-events: none;
}

@media (min-width: 1920px) {
  .section-bg-bottom {
    background-size: contain !important;
    transform: translateY(65%);
  }
}

@media (min-width: 1024px) {
  .section-container {
    margin: 120px 0;
  }

  .section-container .title {
    font-size: 3rem !important;
    /* line-height: 58px; */
  }

  .section-content img {
    width: 160px;
  }

  .section-content {
    flex-direction: row !important;
    padding: 230px 60px;
  }

  .bg-icon1 {
    width: 300px !important;
  }

  .bg-icon2 {
    width: 280px !important;
  }
}

@media (min-width: 768px) {
  .section-container {
    margin: 6rem 0;
    padding: unset !important;
  }

  .section-container .title {
    font-size: 2.5rem;
    align-self: start;
    text-align: left;
  }

  .section-content .text {
    text-align: start !important;
  }

  .section-content {
    flex-direction: row !important;
    padding: 160px 40px;
  }

  .section-content img {
    width: 120px;
  }

  .content {
    width: 70%;
    gap: 1rem !important;
  }

  .section-container .img-container {
    width: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .bg-icon1 {
    width: 230px;
    transform: translateX(-25%);
  }

  .bg-icon2 {
    width: 210px;
    transform: translateX(10%);
  }

  .section-bg-bottom {
    background-size: auto;
    transform: translateY(65%);
  }
}</style>
