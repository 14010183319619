const servicesInfo = {
    32: {
        title: 'Beskontaktno pranje',
        description: "Euro Petrol samouslužni objekti za pranje funkcionišu na sledeći način: bez kontakta i uklanjaju čak i najtvrđe nečistoće."
    },
    33:{
        title: 'Automatsko pranje automobila',
        description: "Operite Vaš auto brzo i lako, najkvalitetnijim preparatima za pranje i voskiranje u našim modernim automatskim praonicama."
    },
    35:{
        title: 'Kontrola pritiska u gumama',
        description: "Mislite na svoju sigurnost u vožnji i redovno proveravajte pritisak u pneumaticima na našim benzinskim stanicama."
    },
    36:{
        title: 'Samouslužno usisavanje',
        description: "Naši samouslužni usisivači, izuzetne snage i laki za upotrebu, učiniće Vaš auto čistim iznutra u svega nekoliko minuta"
    },
    38:{
        title: 'Plaćanje karticama',
        description: "Primamo sve poznatije kartice, tako da Vam gotovina na našim benzinskim stanicama neće biti potrebna."
    },
    39:{
        title: 'Internet/Wi-Fi',
        description: "Dok točite gorivo proverite dešavanja na Internetu. Naše konekcije su brze, a za detalje se raspitajte kod zaposlenih."
    },
    40:{
        title: 'Radno vreme 24 časa',
        description: "U koje god doba dana i noći da dođete, Euro Petrol benzinske stanice biće otvorene za Vas, a naši zaposleni na raspolaganju."
    },
    42:{
        title: 'Lion Shop',
        description: "Naši Lion Shop-ovi snabdeveni su bogatim asortimanom proizvoda, od najsitnijih potrepština do auto kozmetike. Za svakoga po nešto."
    },
    43:{
        title: 'Restoran',
        description: "Napravite predah od puta i napunite se dobrom energijom uz ukusan obrok u prijatnom ambijentu naših restorana."
    },
    321:{
        title: 'Euro Petrol Kartica',
        description: "Euro Petrol platna kartica predstavlja idealno sredstvo plaćanja za pravna lica!"
    },
    324:{
        title: 'Café',
        description: "Priuštite sebi kratak predah od puta i uživajte u kafi, sa prijateljima ili sami, u prijatnom ambijentu naših lokala."
    },
    325:{
        title: 'Kafa za poneti',
        description: "Okrepite se u pokretu i skoncentrišite se na bolju vožnju jer se na našim benzinskim stanicama možete snabdeti kafom za poneti."
    },
    327:{
        title: 'Menjačnica',
        description: "U okviru naših benzinskih stanica možete bezbedno razmeniti devize u ovlašćenim menjačnicama."
    },
    438:{
        title: 'Euro Petrol Klub kartica',
        description: "Postanite korisnik Euro Petrol Kartice i kupujte gorivo jeftinije i do 7 dinara po litru, uz razne druge specijalne pogodnosti!"
    },
    524:{
        title: 'ENP dopune za TAG uređaj',
        description: "Kod nas možete dopuniti TAG uređaj za elektronsku naplatu putarine. Prolazite kroz naplatne rampe na auto putu bez zadržavanja."
    },
    563:{
        title: 'Kamping',
        description: ""
    },
    572:{
        title: 'AdBlue',
        description: ""
    },
    584:{
        title: 'EV Charger',
        description: "Punjač za automobile na električni pogon."
    },
    610:{
        title: 'EASY Paketi',
        description: "EASY PAKETI podrazumevaju komplet - TAG uređaj za ENP i dopunu."
    },

};

export default servicesInfo;