<template>
    <HeaderSection />
    <div class="container-fluid d-flex justify-content-center my-5">
      <img class="img-fluid mx-h-300 header-img" src="../assets/pages-images/usluge-header.jpg" alt=""/>
    </div>

    <!-- First title section -->
    <div class="container title-section text-center d-flex flex-column align-items-center mb-4">
        <h1 class="section-title">Usluge</h1>
        <!-- <p class="section-subtitle text-center w-75 mt-2">Pogledajte sve naše proizvode na jednom mestu.</p> -->
    </div>
    <!-- First title section end -->

    <div class="container mb-5">

        <!-- Product grid -->
        <div class="row my-4" id="generator">
            <div class="col-lg-4 p-3" v-for="service in services" :key="service.product">
                    <div class="card d-flex flex-column h-100" style="border: none;">
                            <div class="position-relative">
                                <img v-if="service.uri" :src="'/img/services/' + service.product + '.jpg'" class="service-img img-fluid hw-150" alt="EDT Lion Dynamic">
                                <img v-if="service.uri" :src="service.uri" style="position: absolute; bottom: -10px; left: -15px;" class="service-icon img-fluid hw-150" alt="EDT Lion Dynamic">
                            </div>
                            <div class="w-100 pt-3">
                                <span class="service-title fw-bold text-capitalize">{{service.title}}</span>
                            </div>
                            <div class="flex-grow-1">
                                <p class="service-subtitle">{{service.description ? service.description : service.title}}</p>
                            </div>

                            <div v-if="customTargets[service.product]">
                                <a :href="customTargets[service.product]" class="uline d-flex justify-content-start align-items-center">Pogledajte više ->
                                </a>
                            </div>

                            <div v-else>
                                <a :href="'benzinske-stanice/' + service.product" class="uline d-flex justify-content-start align-items-center">Pogledajte na mapi ->
                                </a>
                            </div>
                        <!-- <router-link :to="`/lion-expert/proizvodi/${service.slug.current}`" class="btn btn-main product-btn d-flex gap-2 lh-lg rounded-0 text-white" type="submit"><i class="ri-eye-line"></i> Pogledaj proizvod</router-link> -->
                    </div>
            </div>
        </div>
    </div>
</template>


<script>
import HeaderSection from "../components/UI/AlternativeHeader.vue";
import sanity from "../client";
import imageUrlBuilder from "@sanity/image-url";
import moment from 'moment';
import { ref, onValue } from "firebase/database";
import epk_db from '@/getFuelPrices';
import servicesInfo from '@/servicesInfo';


const imageBuilder = imageUrlBuilder(sanity);

const query = `*[_type == "products"] | order(title desc){
  _id, title, categories, slug, createdAt, description, shortDescription,
  "image": mainImage{
        asset->{
        _id,
        url
    },
  },
}[0...50]`;

export default {
    name: "Blog",
    data() {
        return {
            loading: true,
            products: [],
            services: [],
            customTargets: {
            '43': 'https://euro-petrol.com/najnovije-vesti/napravite-pauzu-u-nasem-restoranu', // Restoran
            '438': 'https://euro-petrol.com/euro-petrol-klub', // EP Klub Kartica
            '524': 'https://euro-petrol.com/najnovije-vesti/izbegnite-guzvu-na-putevima' // ENP
            }
        };
    },
    async created() {
        // this.fetchData();
        const euroPetrolBody = JSON.stringify({"url":"https://app.lion-group.rs/epk/v2","action":"updateBS","data":{}});

        // POST request using fetch with async/await
        const requestOptions = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
            },
            body: euroPetrolBody
        };
        const response = await fetch("https://app.lion-group.rs/epk/v2", requestOptions);
        const data = await response.json();

        const fuelData = ref(epk_db, 'epk');
        this.fuelListLoaded = false;
        this.stationInfoParsed = false;

        onValue(fuelData, (snapshot) => {

            this.fuelList = JSON.parse(JSON.stringify(snapshot.val()));
            this.fuelListLoaded = true;

            // this.parseFuelInfo();
        });

        const responseData = data.data;
        responseData.services.forEach( (service) => {
            if (service.category === 1) {
                // Get the description and the image from the hardcoded list
                if (servicesInfo[service.product]) {
                    service.description = servicesInfo[service.product].description;
                }

                this.services.push(service);
            }
        });

        this.loading = false;

        // // Main gas stations array
        // this.gasStations = data;
        // // Gas Stations -> BS
        // this.gasStationsBs = this.gasStations.data.bs;
        // // Sort Gas Stations by Name
        // this.sortedStationLocations = this.gasStationsBs.sort((a, b) =>
        // a.city.localeCompare(b.city)
        // );
        // // Gas station services and fuels - list of all of the possible items
        // this.allStationServices = this.gasStations.data.services;
        // // Gas station connector for services
        // this.servicesConnector = this.gasStations.data.stationServices;

        // // Gas station connector for fuels
        // this.fuelsConnector = this.gasStations.data.stationFuels;

        // // loop through all the stations
        // this.gasStationsBs.forEach((station) => {
        //     // this will contain the services and fuels for the specific station
        //     station.services = [];
        //     station.fuels = [];
        //     station.location = { lat: parseFloat(station.lat), lng: parseFloat(station.lng) };

        //     // console.log(this.stationLocation);

        //     // list of services this station has
        //     var servicesOfThisStation = this.servicesConnector[station.idmag_nord];

        //     // list of fuels this station has
        //     var fuelListOfThisStation = this.fuelsConnector[station.idmag_nord];

        //     // loop through the service ids of this station, and if we find a match in the all service list, return it
        //     servicesOfThisStation.forEach((serviceId) => {

        //         var serviceInfo = this.allStationServices.find((serviceListItem) => {
        //         if (serviceListItem.product && serviceListItem.product == serviceId) {
        //             return true;
        //         }
        //         return false;
        //         });

        //         // avoid passing by reference
        //         var serviceInfoItem = Object.assign({}, serviceInfo);

        //         // add the main info into the services array
        //         station.services.push(serviceInfoItem);
        //     });

        //     // loop through the fuel ids of this station, and do the same as above
        //     fuelListOfThisStation.forEach((fuelId) => {

        //         var fuelInfo = this.allStationServices.find((serviceListItem) => {
        //         if (serviceListItem.product && serviceListItem.product == fuelId) {
        //             return true;
        //         }
        //         return false;
        //         });

        //         // avoid passing by reference
        //         var fuelInfoItem = Object.assign({}, fuelInfo);

        //         // add the fuel info to the fuels array
        //         station.fuels.push(fuelInfoItem);
        //     });
        // });

        // this.gasStationsResponse = this.gasStationsBs;
        // console.log(this.gasStationsResponse);
        // this.stationInfoParsed = true;
        // this.parseFuelInfo();

        // parsed data, use this
        // console.log("this.gasStationsBs", this.gasStationsBs);
    },
    methods: {

        getServiceImageUrlFor(id) {
            return '/img/services/' + id + '.jpg';
        },

        moment,

        imageUrlFor(source) {
            return imageBuilder.image(source);
        },

        fetchData() {
            this.error = this.product = null;
            this.loading = true;
            sanity.fetch(query).then(
                (products) => {
                    // this.loading = false;
                    this.products = products;
                },
                (error) => {
                    this.error = error;
                }
            );
        },

        parseFuelInfo() {
            if (this.fuelListLoaded === false || this.stationInfoParsed === false) {
                // make sure we parse only when both request have finished
                return;
            }

            this.gasStationsResponse.map((station) => {

                var stationId = station.idmag_nord;

                station.fuelPricesLastUpdated = this.fuelList.azurirano;

                station.fuels.map((stationFuel) => {
                var fuelId = stationFuel.product;
                stationFuel.regularPrice = null;
                stationFuel.discountPrice = null;

                if (this.fuelList.goriva[stationId] && this.fuelList.goriva[stationId][fuelId]) {
                    stationFuel.regularPrice = this.fuelList.goriva[stationId][fuelId];
                }

                if (this.fuelList.gorivaPopusti[stationId] && this.fuelList.gorivaPopusti[stationId][fuelId]) {
                    stationFuel.discountPrice = this.fuelList.gorivaPopusti[stationId][fuelId];
                }

                return stationFuel;
                });

                return station;
            });

            this.fuelParsingFinished = true;
            this.gasStationsResponse = this.gasStationsBs;
        }
    },

    computed:{
    },

    components: {
        HeaderSection,
    },
};

</script>

<style scoped>
a{
    color: inherit;
    text-decoration: none;
}

.header-img {
    max-height: 500px;
    width: 100%;
}

.categories-gap{
    gap: 3rem;
}

.bg-main i, .border:hover i{
    color: var(--color-secondary);
}

.border:hover{
    background-color: var(--color-main);
    color: white;
}

.hw-250{
    height: 250px;
    width: auto;
}

.service-title{
    font-weight: bold;
    font-size: 22px;
    text-align: center;
    color: #676057;
    margin: 15px 0;
}

.top-right{
    top: 25px;
    right: 25px;
}

.product-btn{
    opacity: 0;
    display: none;
    position: absolute;
    top: 50%;
}

.card:hover .product-btn{
    opacity: 1 !important;
    display: flex !important;
}

@media (max-width: 576px) {
  .categories-gap{
    gap: 0.5rem;
  }
}

.service-img {
    max-height: 250px;
    width: 100% !important;
}

.service-subtitle {
    font-size: 14px;
    margin-top: 0.2rem;
}

.bbb {
    display: flex !important;
    width: 100vw !important;
    height: 100vh !important;
}

.leeeft {
    flex: 20 !important;
}

.riggght {
    flex: 80 !important;
}

.service-icon {
    max-width: 100px !important;
}

.service-bottom-section {
    min-height: 115px;
}

.uline {
    text-decoration: underline;
}
</style>
