<template>
  <div class="d-flex justify-content-center position-relative">
    <div class="section-container">
      <div class="section-content d-flex flex-column justify-content-center gap-3">
        <div class="title-img-container position-relative d-flex flex-column">
          <span class="main-title mx-3">Preuzmite aplikaciju</span>
          <img class="image px-4" src="/img/phones.png" alt="Download" />
          <div class="section-bg-decor" />
        </div>
        <img class="image d-none d-md-block" src="/img/phones.png" alt="Download" />
        <div class="d-flex flex-column gap-3 mx-3 mx-md-0">
          <span class="main-title desktop-title align-self-baseline">Preuzmite aplikaciju</span>
          <div class="link-cards mt-3 d-flex flex-column gap-3">
            <!-- App Store -->
            <a class="link-card d-flex flex-row gap-4" target="_blank"
              href="https://apps.apple.com/us/app/euro-petrol/id1459216332">
              <img class="link-icon" src="/icon/appstore.png" alt="Store icon" />
              <div class="texts-container d-flex flex-column">
                <div class="text-icon d-flex flex-row justify-content-between">
                  <span class="text text-white">Preuzmite sa</span>
                  <i class="ri-external-link-line text-white" />
                </div>
                <span class="title text-white text-left align-self-auto">App Store</span>
              </div>
            </a>
            <!-- Play Store -->
            <a class="link-card d-flex flex-row gap-4" target="_blank"
              href="https://play.google.com/store/apps/details?id=com.europetrol.epk&hl=en_US&gl=US&pli=1">
              <img class="link-icon" src="/icon/playstore.png" alt="Store icon" />
              <div class="texts-container d-flex flex-column">
                <div class="text-icon d-flex flex-row justify-content-between">
                  <span class="text text-white">Preuzmite sa</span>
                  <i class="ri-external-link-line text-white" />
                </div>
                <span class="title text-white text-left align-self-auto">Play Store</span>
              </div>
            </a>
            <!-- Huawei Store -->
            <a class="link-card d-flex flex-row gap-4" target="_blank"
              href="https://appgallery.huawei.com/app/C104584461">
              <img class="link-icon" src="/icon/huaweistore.png" alt="Store icon" />
              <div class="texts-container d-flex flex-column">
                <div class="text-icon d-flex flex-row justify-content-between">
                  <span class="text text-white">Preuzmite sa</span>
                  <i class="ri-external-link-line text-white" />
                </div>
                <span class="title text-white text-left align-self-auto">AppGallery</span>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="section-bg-decor d-none d-md-block" />
  </div>
</template>

<script></script>

<style scoped>
a {
  text-decoration: none;
}

.section-container {
  margin: 8rem 0 4rem;
  position: relative;
  z-index: 1;
  width: 100%;
  max-width: 1330px;
  height: fit-content;
}

.section-content {
  z-index: 1;
  position: relative;
}

.section-container .title,
.main-title {
  font-size: 2rem;
  color: var(--color-main);
  /* Main color */
  color: #006d35;
  /* Figma */
  font-weight: 700;
  line-height: 38px;
  text-align: center;
  display: flex;
  width: fit-content;
  align-self: center;
}

.section-bg-decor {
  background-image: url("/img/bg-decor.png");
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  width: 100%;
  height: 70%;
  bottom: 0;
  z-index: 0;
  pointer-events: none;
}

.link-cards {
  min-width: fit-content;
}

.link-card {
  background-color: #333333;
  padding: 1rem;
  padding-left: 1.5rem;
  border-radius: 1rem;
  /* gap: 1.5rem; */
}

.link-icon {
  display: flex;
  align-self: center;
  width: 56px;
  height: 56px;
}

.text-icon {
  line-height: 1 !important;
}

.text-icon i {
  font-size: 1.5rem;
}

.texts-container {
  width: 100%;
}

.texts-container .text {
  font-weight: 600;
}

.desktop-title {
  display: none !important;
}

img.image {
  width: 100%;
  object-fit: contain;
}

.title-img-container .section-bg-decor {
  z-index: -1;
}

@media (min-width: 768px) {
  .section-container {
    margin: 6rem 40px;
  }

  .section-container .main-title {
    font-size: 2.5rem;
    /* width: max-content !important; */
  }

  .title-img-container {
    display: none !important;
  }

  .section-content {
    flex-direction: row !important;
    align-items: center;
    gap: 1.5rem;
  }

  .desktop-title {
    display: block !important;
  }

  img.image {
    width: 60%;
  }
}

@media (min-width: 1024px) {
  .section-container {
    margin: 120px 60px;
  }

  .section-container .main-title {
    font-size: 3rem !important;
  }

  img.image {
    width: auto;
  }
}

@media (min-width: 2200px) {
  .section-bg-decor {
    height: 100%;
  }
}
</style>
