<template>
  <div class="d-flex justify-content-center">
    <div class="section-container position-relative">
      <div class="section-content d-flex flex-column justify-content-center align-items-center">
        <div class="title-img-container d-flex flex-column">
          <span class="title mobile-title">4 laka koraka</span>
          <img class="image" src="/img/cetirilakakoraka.png" alt="Download" />
        </div>
        <img class="image" src="/img/cetirilakakoraka.png" alt="Download" />
        <div class="d-flex flex-column gap-3">
          <span class="title desktop-title align-self-baseline">4 laka koraka</span>
          <div class="four-step-container d-flex flex-column gap-4">
            <div class="four-step d-flex flex-column gap-2">
              <!-- First -->
              <div class="step d-flex align-items-center gap-3">
                <span>
                  <img src="/icon/firststep.png" alt="First step icon" />
                </span>
                <div class="texts-container">
                  <span class="number text-white">1.</span>
                  <span class="text-white">Unesite Vaš PIN kod</span>
                </div>
              </div>
              <!-- Second -->
              <div class="step d-flex align-items-center gap-3">
                <span>
                  <img src="/icon/secondstep.png" alt="Second step icon" />
                </span>
                <div class="texts-container">
                  <span class="number text-white">2.</span>
                  <span class="text-white">Napunite rezervoar</span>
                </div>
              </div>
              <!-- Third -->
              <div class="step d-flex align-items-center gap-3">
                <span>
                  <img src="/icon/thirdstep.png" alt="Third step icon" />
                </span>
                <div class="texts-container">
                  <span class="number text-white">3.</span>
                  <span class="text-white">Skenirajte QR kod na točionom mestu</span>
                </div>
              </div>
              <!-- Fourth -->
              <div class="step d-flex align-items-center gap-3">
                <span>
                  <img src="/icon/fourthstep.png" alt="Fourth step icon" />
                </span>
                <div class="texts-container">
                  <span class="number text-white">4.</span>
                  <span class="text-white">Platite putem aplikacije</span>
                </div>
              </div>
            </div>
            <span class="title text-white">Sve je spremno!</span>
            <img class="bg-decoration" src="/img/fourstepbgdecoration.png" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script></script>

<style scoped>
.section-container {
  padding: 4rem 1rem;
  max-width: 1330px;
  width: 100%;
}

.section-container .title {
  font-size: 2rem;
  color: var(--color-main);
  /* Main color */
  color: #006d35;
  /* Figma */
  font-weight: 700;
  line-height: 38px;
  text-align: center;
  display: flex;
  width: fit-content;
  align-self: center;
}

.section-content {
  position: relative;
  z-index: 1;
}

.four-step-container {
  background-color: #005f30;
  border-radius: 2rem;
  overflow: hidden;
  padding: 2rem 1rem;
  position: relative;
  width: min-content;
}

.four-step-container .title {
  font-size: 38px;
  font-weight: 800;
  text-align: center;
  z-index: 1;
  position: relative;
  text-shadow: 0px -2px 12px #00210c73;
  width: max-content;
  margin: 0 2vw;
}

.four-step {
  border-radius: 2rem;
  z-index: 1;
  position: relative;
  width: fit-content;
}

.step {
  padding: 1.25rem 1rem;
  background-color: #33333333;
  backdrop-filter: blur(0.2rem) brightness(85%);
  border-radius: 1rem;
  width: 100%;
}

.step span:has(img) {
  height: 32px;
  width: 32px;
  min-width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.step span img {
  max-width: 85%;
  max-height: 32px;
}

.texts-container {
  display: flex;
  gap: 0.5rem;
  font-size: 18px;
  line-height: 1;
  height: fit-content;
  align-self: center;
}

.texts-container .number {
  font-weight: 900;
}

.bg-decoration {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 0;
  border-radius: 2rem;
  pointer-events: none;
  width: 100%;
}

.title-img-container img {
  width: 100%;
  display: none;
}

.desktop-title {
  display: none !important;
}

img.image {
  width: 100%;
  transform: translateY(1rem);
}

@media (min-width: 1024px) {
  .section-container {
    padding: 120px 60px;
  }

  .section-container .title {
    font-size: 3rem !important;
  }

  .texts-container {
    font-size: 22px !important;
  }

  .step span:has(img) {
    height: 40px;
    width: 40px;
    min-width: 40px;
  }

  .step span img {
    max-height: 40px;
  }

  .title-img-container {
    display: none !important;
  }

  .section-content {
    flex-direction: row !important;
    gap: 1.5rem;
  }

  .desktop-title {
    display: block !important;
  }

  img.image {
    transform: unset !important;
  }
}

@media (min-width: 768px) {
  .section-container {
    padding: 6rem 40px;
  }

  .section-container .title {
    font-size: 2.75rem !important;
  }

  .texts-container {
    font-size: 20px;
  }
}
</style>
