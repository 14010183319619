<template>
  <div class="fifth-section container d-flex justify-content-center px-3">
    <div class="fifth-section-content mx-auto d-flex flex-column align-items-center gap-lg-3">
      <div class="content-left position-relative">
        <h1 class="d-lg-none position-absolute start-50 text-center translate-middle-x w-100 m-0">Brza i sigurna uplata
        </h1>
        <img src="/img/mobile-app-mockup-2.png" />
        <img class="img-safe position-absolute end-0 top-50 translate-middle-y" src="/img/safe.png" />
      </div>
      <div class="content-right">
        <h1 class="d-none d-lg-block">Brza i sigurna uplata</h1>
        <p class="m-0">Mi se brinemo da vaše uplate prođu bez ikakvih problema. Proces dodavanja platnih kartica je jednostavan, efikasan i siguran</p>
      </div>
    </div>
  </div>
</template>

<script></script>

<style scoped>
.fifth-section {
  margin: 4rem auto 7.5rem;
}

.fifth-section .content-left,
.fifth-section .content-right {
  width: 100%;
}

.fifth-section .content-left img {
  width: 100%;
}

.fifth-section .content-left img:nth-child(2) {
  padding-right: 1.25rem;
}

.fifth-section .img-safe {
  z-index: -1;
}

.fifth-section h1 {
  color: #006D35;
  font-weight: 700;
  font-size: 2rem !important;
}

.fifth-section .content-right h1 {
  font-size: 3rem !important;
  width: 75%;
}

.fifth-section p {
  color: #191C19;
  font-size: 1.5rem;
  line-height: 1.75rem;
  text-align: center;
}

@media (min-width: 768px) {

  .fifth-section .content-left,
  .fifth-section .content-right {
    width: 75%;
  }
}

@media (min-width: 992px) {
  .fifth-section {
    margin: auto;
  }

  .fifth-section .fifth-section-content {
    flex-direction: row !important;
  }

  .fifth-section .content-left,
  .fifth-section .content-right {
    width: 50%;
  }

  .fifth-section .content-left img {
    width: 90%;
  }

  .fifth-section .content-left img:nth-child(2) {
    float: right;
    padding-right: 0;
    margin-right: 0.75rem;
  }

  .fifth-section p {
    text-align: start;
  }
}
</style>
