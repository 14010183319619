<template>
    <MainCarousel />
    <AboutEP />
    <MobileApp />
    <JobVacancies />
    <ElectricChargers />
    <BlogSection />
    <NewsletterSection />

    <!--Scroll to top Button-->
    <a @click="scrollToTop" id="scrollUpButton" class="p-4 bg-light z-top rounded rounded-circle fs-5 d-none d-md-flex">
      <i class="ri-arrow-up-line"></i>
    </a>

</template>

<script>
import MainCarousel from "../components/carousels/MainCarousel.vue";
import AboutEP from "../components/sections/AboutEP.vue";
import MobileApp from "../components/sections/AlternativeMobileApp.vue";
import ElectricChargers from "../components/sections/ElectricChargers.vue";
import JobVacancies from "../components/sections/EuroPetrolKlub.vue";
import NewsletterSection from "../components/sections/NewsletterSection.vue";
import BlogSection from "../components/sections/BlogSection.vue";

export default {
  setup() {
    function scrollToTop() {
			window.scrollTo(0, 0);
		}
    
		return {
			scrollToTop
		};
  },

  created() {},

  components: {
    MainCarousel,
    AboutEP,
    MobileApp,
    ElectricChargers,
    JobVacancies,
    NewsletterSection,
    BlogSection,
  },
};

</script>

<style>

#scrollUpButton {
  width: 4.17em;
  height: 4.17em;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  border: 1px solid #dadada;
  color: inherit;
  position: absolute;
  bottom: 30px;
  right: 30px;
}

#scrollUpButton:hover {
  transform: scale(1.2);
  transition: all 0.2s ease-in-out;
  background-color: var(--color-main) !important;
  color: white;
}

.section-title {
    margin: 0 !important;
    color: #00a651;
    font-size: 4.17em;
    font-weight: 800;
    cursor: default;
}

.section-subtitle {
    margin-top: 1.04em;
    margin-bottom: 1.04em;
    padding-top: 0px;
    padding-bottom: 0px;
    font-size: 1.04em;
    line-height: 1.5em;
    font-weight: 500;
    cursor: default;
}

.sectionFourImg {
  width: 100%;
  max-height: 692px;
  object-fit: cover;
  object-position: bottom;
}

.border-responsive {
  border: 0px;
  border-right: 1px solid #E7E7E7;
}

.bg-pin{
  background-color: #FBFBFB !important;
}

.filter-title{
    font-weight: 600;
    font-size: 15px;
    color: inherit;
}

.filter-placeholder{
  color: var(--gray-3);
}

.outline-0{
  outline: none;
}

.ticket-title{
    font-weight: 600;
    color: inherit;
}

.ticket-placeholder{
  color: var(--gray-3);
  font-size: 15px;
}

.w-social {
  width: calc(100%/3 - 25px);
}

.v-line-separator {
    border-left: 1px solid #E7E7E7; /* Border on the left */
    width: 1px;
    height: 50px;
}

.h-line-separator {
    border-bottom: 1px solid #E7E7E7; /* Border on the bottom */
    width: 100%;
    height: 1px;
}

.w-fit-content{
  width: fit-content;
}

.w-max-content{
  width: max-content;
}

.w-100px{
  width: 100px;
}

.w-250px{
  width: 250px;
}

.w-500px{
  width: 500px;
}

.visible {
  display:block;
}

.cursor-pointer {
  cursor: pointer;
}

@media (max-width: 992px) {
  .w-social {
    width: 80%;
  }
  .border-responsive {
    border: 0px;
    border-bottom: 1px solid #E7E7E7;
    width: 100%;
  }
}

@media (max-width: 576px) {
  .w-500px, .w-fit-content, .w-max-content{
    width: 80vw;
  }
}


@keyframes splash-animation {
  0% {
    transform: translateX(0);
  }

  99% {
    transform: translateX(-100vw);
    visibility: visible;
  }

  /*Without this screen size changes may show the plash screen again*/
  100% {
    visibility: hidden;
  }
}

@keyframes navbar-open {
  from {
    visibility: visible;
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes navbar-close {
  0% {
    opacity: 1;
  }
  
  99% {
    opacity: 0;
    visibility: visible;
  }

  100% {
    visibility: hidden;
  }
}
/* Text Colors */
.text-second {
  color: var(--color-secondary) !important;
}

.text-main {
  color: var(--color-main) !important;
}

.text-second:hover{
  color: var(--color-secondary-hover) !important;
}

.text-main:hover{
  color: var(--color-main-hover) !important;
}

.text-outline {
  -webkit-text-stroke: 1px white;
  color: transparent;
  /*border: 1px solid white;*/
}
/*--Variables--*/

/*--Splash Screen--*/

#splash {
  z-index: 100;
}

/*Left to Right animation*/
.splash-animate {
  animation: splash-animation 0.5s ease-out forwards;
}

.backdrop-image {
  height: 100vh;
  width: 100vw;
  background: url("../assets/media/GasPumpImage1.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
/*--Splash Screen--*/

/*--Tools--*/
.v-line {
  border-left: 1px solid rgb(255, 255, 255, 0.2);
  width: 1px;
  height: 42px;
}

.h-line {
  border-top: 1px solid rgb(255, 255, 255, 0.2);
  width: 100%;
  height: 1px;
}

.h-line-small {
  width: 15px;
  height: 1px;
  border-top: 1px solid var(--color-secondary);
}

.z-top {
  z-index: 2;
}

body #magicMouseCursor{
  border: 1px solid var(--color-main-hover) !important;
}

body #magicPointer{
  background: var(--color-secondary-hover) !important;
}

.mw-800{
  max-width: 800px;
}

.bg-white-20{
  background-color: #ffffff27;
}

.vh-100{
  height: 110vh;
}

hr{
  background-color: #adadad;
}
/*--Tools--*/

</style>
