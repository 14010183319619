<template>
    <AlternativeHeader />
    <!-- Main container -->
    <section id="quality-policy" class="container py-5">
        <!-- Second paragraph section -->
        <div class="title-section py-5 my-3">
            <h1 class="section-title text-left pb-3">Politika kvaliteta, životne sredine i bezbednosti i zdravlja na radu</h1>
            <div class="row text-left">
                <div class="col-lg-12">
                    <p class="section-subtitle">
                        <strong>D.O.O. EURO PETROL</strong> teži da bude najbolji u onome što radi i to je usmerenje iz koga proizilaze osnovni principi Politike kvaliteta, životne sredine i bezbednosti i zdravlja na radu.
                    </p>

                    <p class="section-subtitle">
                        Ostvarivanje kvaliteta uz adekvatno upravljanje procesima omogućuje:
                    </p>

                    <ul>
                        <li>Ispunjenje zahteva standarda SRPS ISO 9001:2015;</li>
                        <li>Stalno unapređenje efektivnosti i efikasnosti integrisanog sistema menadžmenta;</li>
                        <li>Stalno unapređenje kvaliteta proizvoda/usluga i ispunjenje očekivanja kupaca/korisnika;</li>
                        <li>Utvrđivanje i upravljanje procesima koji doprinose zadovoljenju kupaca/korisnika i sprovođenje mera za povećanje zadovoljenja kupaca/korisnika i svih zainteresovanih strana;</li>
                        <li>Stručno usavršavanje i razvoj sistema motivacije zaposlenih kroz stručna usvrašavanja ljudskih resursa kroz interne i eksterne obuke, stimulisanje kreativnosti, inicijalnosti i odgovornosti u radu;</li>
                        <li>Poštovanje zakonskih i drugih zahteva i ispunjenje obaveza za usklađenost;</li>
                        <li>Vođeni principom neprekidnog napretka radimo na izgradnji imidža kompanije i povećanju tržišnog učešća;</li>
                        <li>Prepoznavanje rizika po poslovanje kompanije i sprovođenje proaktivnih akcija zasmanjenje njihovoh uticaja na kompaniju.</li>
                    </ul>

                    <p class="section-subtitle mt-5">
                        <strong>D.O.O. EURO PETROL</strong> pri realizaciji svojih poslovnih aktivnosti vodi brigu o uticajima na životnu sredinu u skaldu sa standardom SRPS ISO 14001:2015, tako što:
                    </p>

                    <ul>
                        <li>Identifikuje sve apsekte životne sredine koji imaju ili mogu imati uticaj na životnu sredinu;</li>
                        <li>Upravlja otpadom kroz pravilno prikupljanje, skladištenje i tretman svih vrsta otpada;</li>
                        <li>Štedi energiju i resurse i smanjuje upotrebu štetnih materija;</li>
                        <li>Vrši obuku i podstiče zaposlene da deluju preventivno u cilju sprečavanja zagađenjaživotne sredine;</li>
                        <li>Smanjuje na minimum sve potencijalne rizike u vezi sa identifikovanim aspektima životne sredine i obavezama za usklađenost.</li>
                    </ul>

                    <p class="section-subtitle mt-5">
                        <strong>D.O.O. EURO PETROL</strong> pri realizaciji svojih poslovnih aktivnosti vodi brigu o uticajima na životnu sredinu u skaldu sa standardom SRPS ISO 14001:2015, tako što:
                    </p>

                    <ul>
                        <li>Ispunjava zahteve standarda SRPS ISO 45001:2018;</li>
                        <li>Stvara zdrave uslove za rad, kako ne bi došlo do profesionalnih bolesti i povreda na radu;</li>
                        <li>Teži ka stalnom poboljšanju OH&S učinaka;</li>
                        <li>Vrši prevenciju povreda i ugrožavanja zdravlja;</li>
                        <li>Edukuje zaposlene da budu svesni svojih pojedinačnih obaveza u pogledu bezbednosti i zdravlja na radu.</li>
                    </ul>

                    <p>Svi zaposleni daju lični doprinos sprovođenju ove politike kroz nastojanje da svaki posao urade kvalitetno i na vreme u cilju ostvarenja poboljšanja poslovanja.</p>

                </div>
            </div>
        </div>
        <!-- Second paragraph section end -->
    </section>
</template>

<script>
import AlternativeHeader from "../components/UI/AlternativeHeader.vue";

export default {
  components: {
    AlternativeHeader
  }
}
</script>