<template>
  <div class="d-flex justify-content-center position-relative">
    <div class="container-max d-flex w-100 justify-content-center align-items-center position-relative">
      <div class="d-flex section-container justify-content-center">
        <div class="section-content d-flex flex-column justify-content-center align-items-center gap-3">
          <div class="img-container">
            <img src="/icon/shopping_cart.svg" alt="Shoping cart" />
          </div>
          <div class="texts-container d-flex flex-column gap-2 justify-content-center">
            <span class="title text-center">Nema više čekanja na kasi</span>
            <span class="text text-center">Platite našom aplikacijom i zaboravite na duge redove.</span>
          </div>
        </div>
      </div>
    </div>
    <div class="custom-lines-container" />
  </div>
</template>

<script>
let isResizeable = true;

function calculateCustomLinesContainerHeight() {
  document.querySelector(".custom-lines-container").style.height = document.querySelector(".section-container").clientHeight + "px";
  document.querySelector(".custom-lines-container").style.maxHeight = document.querySelector(".section-container").clientHeight + "px";
}


export default {
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
      calculateCustomLinesContainerHeight();
    })
  },
  methods: {
    getRandom(min, max, type = null) {
      if (type == null) {
        return rand(min, max);
      }
      return rand(min, max) + type;
    },
    onResize() {
      if (isResizeable) {
        isResizeable = false;
        setTimeout(() => {
          isResizeable = true;
          calculateCustomLinesContainerHeight();
        }, 300);
      }
    }
  }
}
</script>

<style scoped>
.container-max {
  max-width: 1330px;
}

.section-container {
  padding: 4rem 1rem;
  width: 100%;
  overflow: hidden;
}

.section-container img {
  height: 80px;
  aspect-ratio: 1/1;
  align-self: center;
}

.section-container .title {
  color: var(--color-main);
  /* Main green */
  color: #006d35;
  /* Figma */
  font-size: 2rem;
  font-weight: 700;
  line-height: 38px;
}

.section-container .text {
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 29px;
  color: #191c19;
}

.custom-lines-container {
  position: absolute;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  pointer-events: none;
  width: 100%;
  left: 0;
  z-index: -1;
  background-image: url("/img/lines.png");
  background-repeat: no-repeat;
  background-size: 100% 80%;
  background-position: left;
}

.custom-line-row {
  display: flex;
  flex-direction: row;
  z-index: -1;
}

.custom-line {
  border: 2px solid rgb(5, 235, 74);
  border-radius: 4px;
}

@media (min-width: 1024px) {
  .section-container {
    padding: 120px 60px;
  }

  .section-container img {
    height: 160px !important;
  }

  .section-container .title {
    font-size: 3rem !important;
  }
}

@media (min-width: 768px) {
  .section-container {
    padding: 6rem 40px;
  }

  .section-content {
    flex-direction: row !important;
    gap: 1.5rem !important;
    width: 100%;
  }

  .section-container .title {
    font-size: 2.5rem;
    text-align: unset !important;
  }

  .section-container .text {
    text-align: unset !important;
  }

  .section-container img {
    height: 120px;
  }

  .img-container {
    display: flex;
    width: 30%;
    justify-content: center;
  }

  .texts-container {
    width: 70%;
  }

  .custom-line {
    border: 3px solid rgb(78, 226, 122);
  }

  .custom-lines-container {
    top: unset;
    left: 2%;
    width: 55%;
    background-size: 100% 135% !important;
  }
}

@media (min-width: 400px) {
  .custom-lines-container {
    background-size: 100%;
  }
}
</style>
