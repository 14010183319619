<template>
  <div class="py-3 py-lg-5 row w-100">
    <div class="col-lg-3 col-1" v-if="data.imagePosition !== 'left'"></div>
    <div class="px-0 col-lg-9 col-11 position-relative">
      <img
        v-if="data.image?.asset?.url"
        class="object-fit-cover w-100"
        :class="
          data.imagePosition === 'left' ? 'rounded-end-3' : 'rounded-start-3'
        "
        :src="data.image.asset.url"
        :alt="data?.title"
      />
      <div
        v-if="data.title"
        class="py-2 px-4 text-white bg-main fw-bold rounded-3 position-absolute image-text fst-italic"
        :class="{
          'right-gap' : data.imagePosition === 'left',
          'left-gap': data.imagePosition !== 'left',
          'fs-subtitle': data.title.length <= 30,
          'fs-body-large': data.title.length > 30,
        }"
        :style="{
          color: data?.imageTextColor?.hex + '!important',
          backgroundColor: data?.imageTextContainerColor?.hex + '!important',
        }"
      >
        {{ data.title }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "JobImage",

  props: {
    data: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {};
  },

  methods: {},
};
</script>

<style scoped>
.rounded-end-3 {
  border-radius: 0 1rem 1rem 0;
}

.rounded-start-3 {
  border-radius: 1rem 0 0 1rem;
}

.image-text {
  width: fit-content;
  bottom: 0;
  transform: translateY(50%);
}

.left-gap {
  left: 2rem;
}

.right-gap {
  right: 2rem;
}

@media (min-width: 1024px) {
  .left-gap {
    left: 5rem;
  }

  .right-gap {
    right: 5rem;
  }
}

@media (max-width: 1024px) {
  .fs-subtitle {
    font-size: 2.25rem !important;
  }

  .fs-body-large {
    font-size: 1.25rem !important;
  }
}

.fs-subtitle {
  font-size: 2.8rem;
}

.fs-body-large {
  font-size: 1.75rem;
}
</style>
