<template>
  <FirstSection />
  <SecondSection />
  <ThirdSection />
  <FourthSection />
  <FifthSection />
  <SixthSection />
  <SeventhSection />
  <EighthSection />
</template>

<script>
import FirstSection from '@/components/sections/landing-page/FirstSection.vue';
import SecondSection from "@/components/sections/landing-page/SecondSection.vue";
import ThirdSection from "@/components/sections/landing-page/ThirdSection.vue";
import FourthSection from "@/components/sections/landing-page/FourthSection.vue";
import FifthSection from "@/components/sections/landing-page/FifthSection.vue";
import SixthSection from "@/components/sections/landing-page/SixthSection.vue";
import SeventhSection from "@/components/sections/landing-page/SeventhSection.vue";
import EighthSection from "@/components/sections/landing-page/EighthSection.vue";

export default {
  components: {
    FirstSection,
    SecondSection,
    ThirdSection,
    FourthSection,
    FifthSection,
    SixthSection,
    SeventhSection,
    EighthSection
  }
}
</script>

<style scoped></style>
