<template>
    <section id="mobile-application" class="bg-main h-auto py-5">
        <!-- Mobile App Section Start -->
        <div class="container-fluid vh-110 d-flex flex-column align-items-center justify-content-center pb-5 pb-lg-0">
            <div class="container d-flex flex-column align-items-center justify-content-center position-relative pt-lg-5 pb-4 pb-md-5 pb-lg-0">
                <!-- Blog Item -->
                <swiper class="w-phone zi-top mb-lg-4 position-relative" :grabCursor="true" :effect="'flip'" @swiper="onSwiper" @slideChange="onSlideChange" :autoplay='{"delay": 4000, "disableOnInteraction": false }'>
                  <swiper-slide>
                    <a href="https://play.google.com/store/apps/details?id=com.europetrol.epk" target="_blank" rel="noopener" aria-label="iOS Mobile App" class="d-flex flex-column align-items-center">
                        <img src="../../assets/media/mobile-app-mockup-4.png" class="img-fluid w-100 position-relative" alt="" srcset="">
                        <img src="../../assets/media/touching.png" width="50" height="50" class="img-fluid bottom-icon" alt="" srcset="">
                    </a>
                  </swiper-slide>

                  <swiper-slide>
                    <a href="https://apps.apple.com/us/app/euro-petrol/id1459216332" target="_blank" rel="noopener" aria-label="Android Mobile App" class="d-flex flex-column align-items-center">
                        <img src="../../assets/media/mobile-app-mockup-3.png" class="img-fluid w-100 position-relative" alt="" srcset="">
                        <img src="../../assets/media/touching.png" width="50" height="50" class="img-fluid bottom-icon" alt="" srcset="">
                    </a>
                  </swiper-slide>

                  <!-- Text Elements Start -->
                  <div class="d-none d-lg-flex flex-column position-absolute pos-1">
                    <div class="number d-flex justify-content-center align-items-center bg-light p-3 fw-bold"><i class="ri-flashlight-fill fs-5"></i></div>
                    <h4 class="text-light mt-3">Brzo učlanjenje u EPK</h4>
                    <p class="w-text">Euro Petrol mobilna aplikacija obezbeđuje korisnicima brzo i lako učlanjenje u Euro Petrol Klub.</p>
                  </div>
                  <div class="d-none d-lg-flex flex-column position-absolute pos-2">
                    <div class="number d-flex justify-content-center align-items-center bg-light p-3 fw-bold"><i class="ri-bar-chart-fill fs-5"></i></div>
                    <h4 class="text-light mt-3">Evidencija potrošnje goriva</h4>
                    <p class="w-text">Euro Petrol mobilna aplikacija Vam od sad nudi praćenje potrošnje goriva Vašeg vozila.</p>
                  </div>
                  <div class="d-none d-lg-flex flex-column position-absolute pos-3">
                    <div class="number d-flex justify-content-center align-items-center bg-light p-3 fw-bold"><i class="ri-roadster-fill fs-5"></i></div>
                    <h4 class="text-light mt-3">Moj auto</h4>
                    <p class="w-text">Sekcija u okviru koje možete unositi podatke o Vašem automobilu, kreirati podsetnike za bitne datume i pratiti stanje na putevima.</p>
                  </div>
                  <div class="d-none d-lg-flex flex-column position-absolute pos-4">
                    <div class="number d-flex justify-content-center align-items-center bg-light p-3 fw-bold"><i class="ri-gas-station-fill fs-5"></i></div>
                    <h4 class="text-light mt-3">Najbliže benzinske stanice</h4>
                    <p class="w-text">Jedna od prednosti Euro Petrol mobilne aplikacije je interaktivna mapa sa lokacijama svih naših benzinskih stanica.</p>
                  </div>
                  <div class="d-none d-lg-flex flex-column position-absolute pos-5">
                    <div class="number d-flex justify-content-center align-items-center bg-light p-3 fw-bold"><i class="ri-percent-fill fs-5"></i></div>
                    <h4 class="text-light mt-3">Aktuelni popusti i akcije</h4>
                    <p class="w-text">Pored ušteda na gorivu, Euro Petrol mobilna aplikacija prikazuje i uštede na ostalim artiklima i uslugama na koje EPK članovi ostvaruju popuste – na pranje i usisavanje automobila, na auto kozmetiku, Lion ulja itd.</p>
                  </div>
                  <!-- Text Elements End -->
                </swiper>
            </div>
            <div class="d-lg-none d-flex flex-column justify-content-center w-75">
                <div class="d-flex justify-content-between w-100">
                    <div class="text-node" @click="showText(1)" id="text-option-1">
                        <div class="number d-flex justify-content-center align-items-center bg-light p-3 fw-bold active"><i class="ri-flashlight-fill fs-5"></i></div>
                        <h4 class="d-none">Brzo učlanjenje u EPK</h4>
                        <p class="d-none">Euro Petrol mobilna aplikacija obezbeđuje korisnicima brzo i lako učlanjenje u Euro Petrol Klub.</p>
                    </div>
                    <div class="text-node" @click="showText(2)" id="text-option-2">
                        <div class="number d-flex justify-content-center align-items-center bg-light p-3 fw-bold"><i class="ri-bar-chart-fill fs-5"></i></div>
                        <h4 class="d-none">Evidencija potrošnje goriva</h4>
                        <p class="d-none">Euro Petrol mobilna aplikacija Vam od sad nudi praćenje potrošnje goriva Vašeg vozila.</p>
                    </div>
                    <div class="text-node" @click="showText(3)" id="text-option-3">
                        <div class="number d-flex justify-content-center align-items-center bg-light p-3 fw-bold"><i class="ri-roadster-fill fs-5"></i></div>
                        <h4 class="d-none">Moj auto</h4>
                        <p class="d-none">Sekcija u okviru koje možete unositi podatke o Vašem automobilu, kreirati podsetnike za bitne datume i pratiti stanje na putevima.</p>
                    </div>
                    <div class="text-node" @click="showText(4)" id="text-option-4">
                        <div class="number d-flex justify-content-center align-items-center bg-light p-3 fw-bold"><i class="ri-gas-station-fill fs-5"></i></div>
                        <h4 class="d-none">Najbliže benzinske stanice</h4>
                        <p class="d-none">Jedna od prednosti Euro Petrol mobilne aplikacije je interaktivna mapa sa lokacijama svih naših benzinskih stanica.</p>
                    </div>
                    <div class="text-node" @click="showText(5)" id="text-option-5">
                        <div class="number d-flex justify-content-center align-items-center bg-light p-3 fw-bold"><i class="ri-percent-fill fs-5"></i></div>
                        <h4 class="d-none">Aktuelni popusti i akcije</h4>
                        <p class="d-none">Pored ušteda na gorivu, Euro Petrol mobilna aplikacija prikazuje i uštede na ostalim artiklima i uslugama na koje EPK članovi ostvaruju popuste – na pranje i usisavanje automobila, na auto kozmetiku, Lion ulja itd.</p>
                    </div>
                </div>
                <h4 class="text-light mt-3 text-center fs-3 fw-bold">{{ titleText }}</h4>
                <div class="w-100 d-flex justify-content-center">
                    <p class="w-text d-flex text-center">{{ paraText }}</p>
                </div>
            </div>
        </div>
        <!-- Mobile App Section End -->
    </section>
</template>

<script>
  // Import Swiper Vue.js components
  import { Swiper, SwiperSlide } from 'swiper/vue/swiper-vue.js';
  import SwiperCore, {Autoplay,EffectFlip} from 'swiper';

  // install Swiper modules
  SwiperCore.use([Autoplay,EffectFlip]);

  // Import Swiper styles
  import "swiper/swiper-bundle.css";

  import { ref } from '@vue/reactivity';
  import { onMounted } from '@vue/runtime-core';

  export default {
    components: {
      Swiper,
      SwiperSlide,
    },
    setup() {
      const onSwiper = (swiper) => {
          return{
              swiper,
              autoplay: {
                  delay: 1000
              },
          };
      };

      const onSlideChange = () => {};
      
      const titleText = ref("asd");
      const paraText = ref("asd");

      function showText(id) {
        const nums = document.querySelectorAll(".number");
        for(var i = 0; i < nums.length; i++) {
            nums[i].classList.remove('active');
        }

        const el = document.getElementById("text-option-" + id);
        titleText.value = el.childNodes[1].innerText;
        paraText.value = el.lastChild.innerText;
        el.firstChild.classList.toggle('active');
      }

      onMounted(() => {
          titleText.value = document.querySelector("#text-option-1").childNodes[1].innerText;
          paraText.value = document.querySelector("#text-option-1").lastChild.innerText;
      })

      return {
          onSwiper,
          onSlideChange,
          showText,
          titleText,
          paraText
      };
    },
  };
</script>

<style scoped>
@import "../../assets/variables.css";

.active {
    background-color: var(--color-secondary) !important;
}

.number {
    color: var(--color-main);
    width: 45px;
    height: 45px;
    border-radius: 50%;
}

.bottom-icon{
    bottom: -60px;
    position: absolute;
}

.w-text {
    width: 350px;
}

.pos-1 {
    top: 50px;
    left: calc(-10% - 350px);
}

.pos-2 {
    bottom: 50px;
    left: -350px;
}

.pos-3 {
    top: -15%;
    right: -80%;
}

.pos-4 {
    top: 30%;
    right: -100%;
}

.vh-110{
    height: 110vh;
}

.pos-5 {
    bottom: -10%;
    right: -90%;
}

.w-phone {
    width: 35%;
}

.fs-mobile {
    font-size: 180px !important;
}

.fs-20{
    font-size: 20px;
}

@media (max-width: 1200px) {
    .fs-mobile {
        font-size: 13vw !important;
    }

    .pos-1 {
        top: -10%;
        left: -310px;
    }

    .pos-2 {
        bottom: -10%;
        left: -300px
    }

    .pos-3 {
        top: -25%;
        right: -100%;
    }

    .pos-4 {
        top: 30%;
        right: -105%;
    }

    .pos-5 {
        bottom: -20%;
        right: -110%;
    }
}

@media (max-width: 992px) {
    .w-phone {
        width: 60%;
        left: -40px;
    }

    .fs-mobile {
        font-size: 13vw !important;
    }

    .number {
        width: 60px;
        height: 60px;
        font-size: 20px;
    }

    .w-75 {
        width: 50% !important;
    }
}

@media (max-width: 768px) {
    .w-phone {
        width: 38vh;
        left: 0;
    }

    .bottom-icon{
        position: initial;
        margin-top: 20px;
    }

    .number {
        width: 10vw;
        height: 10vw;
        font-size: 4vw;
    }

    .fs-mobile {
        font-size: 16vw !important;
    }

    .w-75 {
        width: 75% !important;
    }
    
    .vh-110{
        height: auto;
    }
}
</style>