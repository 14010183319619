<template>
  <!-- Sticky on-scroll navigation -->
  <nav id="onScrollNav" class="navbar fixed-top navbar-light bg-white navbar-shadow flex-nowrap d-none">
    <div class="container">
        <a class="navbar-brand me-0 p-0" href="#">
          <img src="../../assets/media/logo.png" alt="Logo" width="62" height="62">
        </a>
        <div id="list-example" class="navbar-middle-content d-xl-flex d-none">
            <a class="list-group-item border-0 p-0 navbar-brand active" href="#"><span class="slideUpText">Početna</span></a>
            <a class="list-group-item border-0 p-0 navbar-brand" href="/euro-petrol-klub"><span class="slideUpText">Euro Petrol Klub</span></a>
            <a class="list-group-item border-0 p-0 navbar-brand" href="/benzinske-stanice"><span class="slideUpText">Lokacije</span></a>
            <a class="list-group-item border-0 p-0 navbar-brand" href="/usluge"><span class="slideUpText">Usluge</span></a>
            <a class="list-group-item border-0 p-0 navbar-brand" href="/lion-expert/proizvodi"><span class="slideUpText">Proizvodi</span></a>
            <a class="list-group-item border-0 p-0 navbar-brand" href="/najnovije-vesti"><span class="slideUpText">Aktuelnosti</span></a>
        </div>
        <!-- <div id="list-example" class="navbar-middle-content d-xl-flex d-none">
          <a class="list-group-item border-0 p-0 navbar-brand active" href="#">Početna</a>
          <a class="list-group-item border-0 p-0 navbar-brand" href="#about-euro-petrol">Euro Petrol</a>
          <a class="list-group-item border-0 p-0 navbar-brand" href="#mobile-application">Mobilna aplikacija</a>
          <a class="list-group-item border-0 p-0 navbar-brand" href="#open-positions">EP Klub</a>
          <a class="list-group-item border-0 p-0 navbar-brand" href="#electric-chargers">Električni punjač</a>
          <a class="list-group-item border-0 p-0 navbar-brand" href="#blog">Vesti i novosti</a>
          <a class="list-group-item border-0 p-0 navbar-brand" href="#gas-station-prices">Lokacije</a>
          <a class=" border-0 p-0 b2b-link" href="https://komitenti.lion-group.rs/#!/login" rel="noopener" target="_blank">Komitenti</a>
        </div> -->

      <div class="d-flex align-items-center">
        <a @click="openNavbar" class="btn border text-dark rounded rounded-circle p-4 d-flex align-items-center justify-content-center size-nav-btn-small">
          <i class="ri-menu-3-line fs-4"></i>
        </a>
      </div>
    </div>

  </nav>
  <!-- Sticky on-scroll navigation end -->

  <!--Navbar Start-->
    <nav class="navbar navbar-expand-lg navbar-dark mt-4">
      <div class="d-flex flex-column w-100">
        <!--Navbar First Line-->
        <div class="container-fluid d-flex align-items-center">
          <a class="navbar-brand me-0 p-0" href="#">
            <img src="../../assets/media/logo.png" alt="Logo" width="62" height="62">
          </a>
          <div class="ms-auto" id="navbarSupportedContent">
            <ul class="navbar-nav ms-auto d-flex align-items-center mb-2 mb-lg-0">
              <li class="d-none d-lg-flex nav-item">
                <a href="mailto:info@europ-petrol.com" class="text-second nav-link text-small">info@euro-petrol.com</a>
              </li>
              <li @click="openNavbar" class="nav-item ms-4">
                <button
                  class="btn nav-link text-white border rounded rounded-circle p-4 d-flex align-items-center justify-content-center size-nav-btn pulse" aria-label="Glavni meni">
                  <i class="ri-menu-3-line fs-4"></i>
                </button>
              </li>
            </ul>
          </div>
        </div>
        <!--Navbar Second Line-->
        <div class="h-line mt-3"></div>
          <div class="container-fluid py-2 d-none d-lg-flex justify-content-evenly">
            <a href="#" class="nav-link text-second text-decoration-none fw-bold overflow-hidden"><span class="slideUpText">Početna</span></a>
            <a href="/euro-petrol-klub" class="nav-link overflow-hidden"><span class="slideUpText">Euro Petrol Klub</span></a>
            <a href="/benzinske-stanice" class="nav-link overflow-hidden"><span class="slideUpText">Lokacije</span></a>
            <a href="/usluge" class="nav-link overflow-hidden"><span class="slideUpText">Usluge</span></a>
            <a href="/lion-expert/proizvodi" class="nav-link overflow-hidden"><span class="slideUpText">Proizvodi</span></a>
            <a href="/najnovije-vesti" class="nav-link overflow-hidden"><span class="slideUpText">Aktuelnosti</span></a>
          </div>
          <!-- <div class="container-fluid py-2 d-none d-lg-flex justify-content-evenly">
            <a href="#" class="nav-link text-second text-decoration-none fw-bold overflow-hidden"><span class="slideUpText">Početna</span></a>
            <a href="#about-euro-petrol" class="nav-link overflow-hidden"><span class="slideUpText">Euro Petrol</span></a>
            <a href="#mobile-application" class="nav-link overflow-hidden"><span class="slideUpText">Mobilna aplikacija</span></a>
            <a href="#electric-chargers" class="nav-link overflow-hidden"><span class="slideUpText">Električni punjač</span></a>
            <a href="#open-positions" class="nav-link overflow-hidden"><span class="slideUpText">EP Klub</span></a>
            <a href="#blog" class="nav-link overflow-hidden"><span class="slideUpText">Vesti i novosti</span></a>
            <a href="#gas-station-prices" class="nav-link overflow-hidden"><span class="slideUpText">Lokacije</span></a>
            <a href="https://komitenti.lion-group.rs/#!/login" rel="noopener" target="_blank" class="nav-link overflow-hidden focused-b2b"><span class="slideUpText">Komitenti</span></a>
          </div> -->
        <div class="h-line d-none d-lg-inline"></div>
      </div>
    </nav>
  <!--Navbar End-->
</template>

<script>
import { useStore } from 'vuex';

export default {
  setup() {
    const store = useStore();

    function openNavbar() {
      store.dispatch('openMenu');
    }

    function stickyToTop() {
      var top = window.pageYOffset;
      var stickyEl = document.getElementById('onScrollNav');
      try {
        if (top > window.innerHeight) {
            stickyEl.classList.remove('d-none');
        } else {
            stickyEl.classList.add('d-none');
        }
      }
      catch(err) {
        if(err.message == "Uncaught TypeError: stickyEl is null") {
          console.log("");
        }
      }
    }

  document.addEventListener('DOMContentLoaded', function() {
      stickyToTop();
  });

  window.addEventListener('scroll', function() {
      stickyToTop();
  });

    return {
      openNavbar
    }
  },
}
</script>

<style>
@import url("../../assets/variables.css");

/*--Navbar--*/
.size-nav-btn {
  height: 80px;
  width: 80px;
  transition: all 200ms ease;
  border: #f8f9fa;
}

.size-nav-btn-small{
  height: 60px;
  width: 60px;
  transition: all 200ms ease;
  color: #111;
}

.size-nav-btn-small:hover{
  background: #00a650 !important;
  border: none !important;
}

.size-nav-btn-small:hover i{
  color: white;
}

.size-nav-btn:hover{
  background: #00a650a4 !important;
  border: none !important;
}

.nav-link {
  font-size: 16px;
}

.text-white {
  color: #FFFFFF;
}

.close-navbar {
  animation: navbar-close 0.2s forwards;
}

.open-navbar {
  animation: navbar-open 0.2s forwards;
}
/*--Navbar--*/

/*--Misc--*/

.pulse {
  animation: pulse-animation 1.6s infinite;
}

.pulse:hover{
  animation: none;
}

@keyframes pulse-animation {
  0% {
    box-shadow: 0 0 0 0px rgba(255, 255, 255, 0.315);
  }
  100% {
    box-shadow: 0 0 0 25px rgba(255, 255, 255, 0);
  }
}

.backdrop-image-dark {
  height: 110vh;
  width: 100vw;
  background: #000;  /*-- fallback for old browsers --*/
  background: radial-gradient(rgb(0,0,0,0.6), rgb(0,0,0)), url("../../assets/media/GasPumpImage1.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  top: 0;
}

.backgrop-image-dark{ animation: changeBg 1s infinite;}
@keyframes changeBg{
   0%,100%  {background: url("https://i.stack.imgur.com/YdrqG.png");}
   25% {background: url("https://i.stack.imgur.com/2wKWi.png");}
   50% {background: url("https://i.stack.imgur.com/HobHO.png");}
   75% {background: url("https://i.stack.imgur.com/3hiHO.png");}
}
/*--Misc--*/

.ken-burns{
  animation: move 40s ease;
  -ms-animation: move 40s ease;
  -webkit-animation: move 40s ease;
  -moz-animation: move 40s ease;
  position: absolute;
}

@-webkit-keyframes move {
  0% {
    -webkit-transform-origin: bottom left;
    -moz-transform-origin: bottom left;
    -ms-transform-origin: bottom left;
    -o-transform-origin: bottom left;
    transform-origin: bottom left;
    transform: scale(1);
    -ms-transform: scale(1);
    /* IE 9 */

    -webkit-transform: scale(1);
    /* Safari and Chrome */

    -o-transform: scale(1);
    /* Opera */

    -moz-transform: scale(1);
    /* Firefox */
  }
  100% {
    transform: scale(1.2);
    -ms-transform: scale(1.2);
    /* IE 9 */

    -webkit-transform: scale(1.2);
    /* Safari and Chrome */

    -o-transform: scale(1.2);
    /* Opera */

    -moz-transform: scale(1.2);
    /* Firefox */
  }
}


.nav-link:hover{
  color: var(--color-secondary);
}

.nav-link{
  color: white;
  text-decoration: none;
  transition: all 200ms ease-in-out;
}

.text-small{
  font-size: 14px;
}

.text-small:hover{
  transition: width 0.3s ease 0s, left 0.3s ease 0s;
  text-decoration: underline;
}
</style>

<style scoped>

.navbar-shadow{
  box-shadow: 0 26px 64px 0 rgb(20 20 21 / 10%);
}

.navbar-brand{
  font-size: 16px !important;
}

.navbar-brand:hover{
  color: var(--color-main);
}

.active{
  color: var(--color-main);
  background: none;
  font-weight: 700;
}

.b2b-link{
  color: #000;
  text-decoration: none;
}

.b2b-link:hover{
  color: var(--color-main);
}

.focused-b2b:focus{
  color: white;
}
</style>