<template>
    <AlternativeHeader />
    <!-- Main container -->
    <section id="privay-policy" class="container py-5">
        <!-- Second paragraph section -->
        <div class="title-section my-4">
            <h1 class="section-title text-center">Kontakt informacije</h1>
        </div>
        <!-- Second paragraph section end -->

        <div class="row my-5 mb-2">
            <div class="col-md-4 p-2">
                <div class="card overflow-hidden flex-md-column h-md-250 position-relative">
                     <div class="card-header">
                            <h3 class="fw-bold fs-5 my-2">Prodaja</h3>
                    </div>
                    <div class="card-body p-3 d-flex flex-column position-static">
                        <div class="d-flex flex-column my-2">
                            <p class="card-text fw-bold m-0">Telefon</p>
                            <a href="tel: +381 24 664 664">+381 24 664 664</a>
                        </div>
                        <div class="d-flex flex-column my-2">
                            <p class="card-text fw-bold m-0">Email</p>
                            <a href="mailto:prodaja@euro-petrol.com">prodaja@euro-petrol.com</a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-4 p-2">
                <div class="card overflow-hidden flex-md-column h-md-250 position-relative">
                    <div class="card-header">
                            <h3 class="fw-bold fs-5 my-2">Marketing</h3>
                    </div>
                    <div class="p-3 card-body d-flex flex-column position-static">
                        <div class="d-flex flex-column my-2">
                            <p class="card-text fw-bold m-0">Telefon</p>
                            <a href="tel: +381 64 821 55 27">+381 64 821 55 27</a>
                        </div>
                        <div class="d-flex flex-column my-2">
                            <p class="card-text fw-bold m-0">Email</p>
                            <a href="mailto:marketing@euro-petrol.com">marketing@euro-petrol.com</a>
                        </div>

                        <div class="d-flex flex-column my-2">
                            <p class="card-text fw-bold m-0">Email</p>
                            <a href="mailto:reklamacije@euro-petrol.com">reklamacije@euro-petrol.com</a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-4 p-2">
                <div class="card overflow-hidden flex-md-column h-md-250 position-relative">
                    <div class="card-header">
                        <h3 class="fw-bold fs-5 my-2">Nabavka</h3>
                    </div>
                    <div class="p-3 card-body d-flex flex-column position-static">
                        <div class="d-flex flex-column my-2">
                            <p class="card-text fw-bold m-0">Telefon</p>
                            <a href="tel: +381 64 877 43 31">+381 64 877 43 31</a>
                        </div>
                        <div class="d-flex flex-column my-2">
                            <p class="card-text fw-bold m-0">Email</p>
                            <a href="mailto:nabavka@euro-petrol.com">nabavka@euro-petrol.com</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </section>

</template>

<script>
import AlternativeHeader from "../components/UI/AlternativeHeader.vue";

export default {
    components: {
        AlternativeHeader
    }
}
</script>

<style scoped>
.card a{
    color: var(--color-main);
    text-decoration: none;
}
</style>