<template>
  <div class="d-flex justify-content-center">
    <div class="section-container">
      <div class="section-content">
        <div class="section-blur" />
        <div class="img-container">
          <img src="/img/qrcode.png" alt="QR code" />
        </div>
        <div class="texts-container">
          <span class="title">Skenirajte kod i započnite odmah</span>
          <div class="text-container">
            <i class="ri-download-2-line text-white"></i>
            <span class="text">Skenirajte ovaj kod da biste preuzeli Euro Petrol aplikaciju</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script></script>

<style scoped>
.section-container {
  padding: 4rem 1rem 8rem 1rem;
  max-width: 1330px;
  width: 100%;
}

.section-content {
  background: no-repeat center url("../../../assets/media/GasPumpImage1.jpg");
  background-size: cover;
  border-radius: 2rem;
  box-shadow: 0px 0px 8px 0px #78fc9f80;
  box-shadow: 0px 0px 16px 0px #78fc9f;
  padding: 4rem 1rem 1.5rem 1rem;
  display: flex;
  border-radius: 2rem;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  flex-direction: column;
  position: relative;
}

.section-blur {
  bottom: 0;
  right: 0;
  top: 0;
  left: 0;
  border-radius: 2rem;
  position: absolute;
  z-index: 1;
  /* background-blend-mode: multiply;
background: #00210C66;
background-blend-mode: screen;
background: #00210CCC;
 */
  backdrop-filter: contrast(80%) brightness(70%);
}

.img-container {
  display: flex;
  padding: 0.75rem;
  border-radius: 0.5rem;
  width: 100%;
  justify-content: center;
  background-color: white;
  z-index: 1;
  width: fit-content;
  box-shadow: 0px 0.9019073247909546px 2.705721855163574px 0px #0000004d;
  box-shadow: 0px 3.6076292991638184px 7.215258598327637px 2.705721855163574px #00000026;
}

.img-container img {
  width: 160px;
  height: 160px;
}

.texts-container {
  z-index: 1;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.texts-container .title {
  font-size: 2rem;
  font-weight: 900;
  color: white;
  line-height: 38px;
  width: 100%;
  text-align: center;
  display: flex;
  text-shadow: 1px 2px 6px #00000050, 0 0 0 #00000020, 1px 2px 6px #00000080;
}

.texts-container .text {
  font-size: 18px;
  color: white;
  line-height: 22px;
}

.text-container {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  border-radius: 1rem;
  backdrop-filter: blur(0.4rem) !important;
  padding: 1.25rem 1rem;
}

.text-container i {
  align-self: center;
  font-size: 22px !important;
}

@media (min-width: 1024px) {
  .section-container {
    padding: 120px 60px;
  }

  .section-container .title {
    font-size: 3.5rem !important;
    line-height: 67px !important;
  }

  .texts-container .text {
    font-size: 22px !important;
    line-height: 26px !important;
  }

  .img-container img {
    width: 320px;
    height: 320px;
  }

  .img-container {
    padding: 1.25rem;
    border-radius: 1.5rem;
  }

  .section-content {
    padding: 6.25rem 6.75rem;
  }

  .text-container i {
    font-size: 40px !important;
  }
}

@media (min-width: 768px) {
  .section-container {
    padding: 6rem 40px;
  }

  .section-container .title {
    font-size: 2.75rem;
    text-align: unset !important;
    line-height: 50px;
  }

  .texts-container .text {
    font-size: 20px;
    line-height: 24px;
  }

  .text-container {
    align-items: center;
  }

  .img-container img {
    width: 240px;
    height: 240px;
  }

  .img-container {
    padding: 1rem;
    border-radius: 1.25rem;
  }

  .section-content {
    padding: 4.75rem 4.25rem;
    flex-direction: row;
    gap: 1.5rem;
  }

  .text-container i {
    font-size: 32px;
  }
}</style>
