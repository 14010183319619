<template>

  <AlternativeHeader />

  <div v-if="loading" class="loading vh-100 d-flex justify-content-center align-items-center">
      <img src="../assets/blog/spinner.svg" alt=""/>
  </div>

  <div v-if="error" class="error">
      {{error}}
  </div>

  <!-- Header Start -->
  <div class="d-flex flex-column justify-content-center position-relative">
      <img class="ep-logo" src="../assets/media/logo.svg" />
      <img class="img-fluid mx-h-300 main-image" v-if="job?.image" :src="imageUrlFor(job.image)" />
      <div class="bg-main w-100 px-lg-5 px-3 py-lg-4 py-2 d-flex flex-xl-row flex-column align-items-lg-center justify-content-between">
          <h1 class="fs-title mb-lg-0 fw-bold color-white">{{job?.title}}</h1>
          <div class="d-flex gap-lg-4 gap-2 mb-lg-0 mb-1 flex-wrap align-items-center">
            <template
                v-if="job?.locations"
            >
                <div
                    class="d-flex gap-1 align-items-center h-fit-content"
                    v-for="location, index in job.locations"
                    :key="`location-${index}`"
                >
                    <i class="ri-map-pin-2-line color-white fs-body-large"></i>
                    <span class="color-white fs-body-large fw-bold">{{location}}</span>
                </div>
            </template>
          </div>
      </div>
  </div>
  <!-- Header End -->

  <!-- Content Section Start -->
  <div class="d-flex flex-column align-items-center gap-5 py-lg-5 my-lg-5 py-5">
    <template
      v-if="job?.sections"
    >
      <component
        :is="sectionTypes[section.type]"
        v-for="section in job.sections"
        :key="section._key"
        :data="section"
      />
    </template>
  </div>
  <!-- Content Section End -->

</template>

<script>
import AlternativeHeader from "../components/UI/AlternativeHeader.vue";
import { SanityBlocks } from "sanity-blocks-vue-component";
import sanity from "../client";
import imageUrlBuilder from "@sanity/image-url";
import moment from 'moment';

import JobIcons from "./JobSections/JobIcons.vue";
import JobText from "./JobSections/JobText.vue";
import JobImage from "./JobSections/JobImage.vue";
import JobBottomSection from "./JobSections/JobBottomSection.vue";

const imageBuilder = imageUrlBuilder(sanity);

const query = `*[slug.current == $slug] {
  _id, slug, title, locations,
  "image": mainImage{
    asset->{
      _id,
      url
    }
  },
  sections[]->{
    ...,
    icons[] {
      ...,
      icon {
        asset->{
          _id,
          url
        }
      }
    },
    image {
      asset->{
        _id,
        url
      }
    },
  }
}
`;

const sectionTypes = Object.freeze({
  richText: JobText,
  icons: JobIcons,
  image: JobImage,
  bottomSection: JobBottomSection,
})

export default {
  name: "SingleBlog",

  mounted() {
    window.scrollTo(0, 0);
  },

  data() {
    return {
      loading: true,
      job: [],
      sectionTypes
    };
  },

  created() {
    this.fetchData();
  },

  methods: {
    moment,
    imageUrlFor(source) {
      return imageBuilder.image(source);
    },
    fetchData() {
      this.error = this.job = null;
      this.loading = true;

      sanity.fetch(query, { slug: this.$route.params.slug }).then(
        (job) => {
          this.loading = false;
          this.job = job[0];
        },
        (error) => {
          this.error = error;
        }
      );
    },
  },

  components: {
    AlternativeHeader,
    SanityBlocks,
    JobIcons,
    JobText,
    JobImage,
    JobBottomSection
  },
};
</script>


<style scoped>
.ep-logo {
  position: absolute;
  top: 2rem;
  right: 2rem;
  width: 8rem;
  height: auto;
}

@media (max-width: 1024px) {
  .ep-logo {
      width: 6rem;
  }
}

.h-fit-content {
  height: fit-content;
}

.mx-h-300{
  min-height: 300px;
  object-fit: cover;
}

.vh-100{
  height: 100vh;
}

.main-image{
  max-width: 100%;
  height: auto;
}

.section-title{
  text-transform: initial;
}

@media (max-width: 576px) {
  .main-image{
    max-width: 100%;
  }

  .w-75{
    width: 100% !important;
  }
}

@media (max-width: 1024px) {
  .fs-title {
    font-size: 2.25rem !important;
  }

  .fs-body-large {
    font-size: 1.25rem !important;
  }
}

.fs-title {
  font-size: 2.5rem;
}

.fs-body-large {
  font-size: 1.75rem;
}
</style>
