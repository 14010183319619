<template>
    <AlternativeHeader />
    <!-- Main container -->
    <section id="privay-policy" class="container py-5">
        <!-- Second paragraph section -->
        <div class="title-section py-4 my-3">
            <h1 class="section-title text-left pb-3">Pravila i uslovi korišćenja</h1>
            <div class="row text-left">
                <div class="col-lg-12">
                    <p class="section-subtitle">Ovaj sajt koristi kolačiće. Koristimo kolačiće da unapredimo i analiziramo Vaše iskustvo na našem sajtu i za svrhe marketinga.</p>
                    <p class="section-subtitle">Kolačić je mali tekstualni dokument koji je skladišten na Vaš uređaj dok posećujete sajtove.</p>
                    <p class="section-subtitle fw-bold">Koje vrste kolačića se koriste?</p>
                    <p class="section-subtitle m-0">Postoje različiti kolačići za različite svrhe. Mi, uglavnom, koristimo kolačiće za poboljšavanje Vašeg iskustva na našoj veb stranici i za marketinške svrhe.</p>
                    <ul>
                        <li>Osnovni kolačići</li>
                        <li>Kolačiće analitike</li>
                        <li>Kolačiće marketing i oglašavanja</li>
                    </ul>
                    <p class="section-subtitle">Mi ne kontrolišemo funkcionisanje „kolačića treće strane“. Odgovarajuće treće strane su odgovorne za takve kolačiće i za njihovu vlastitu obradu podataka.</p>

                    <p class="section-subtitle">
                        <b>Osnovni kolačići</b><br>
                        Ovi kolačići su potrebni za omogućavanje osnovnog funkcionisanja sajta, i pomažu nam da unapredimo Vaše korisničko iskustvo prilikom posete našem sajtu npr. omogućava sajtu da upamti korisnikove preferencije.
                    </p>

                    <p class="section-subtitle">
                        <b>Kolačići analitike</b><br>
                        Kolačići analitike koristimo za praćenje posete stranice(anonimno) kako bismo poboljšali korisničko iskustvo i imali uvid u to koju stranicu treba da poboljšamo.
                    </p>

                    <p class="section-subtitle">
                        <b>Marketing kolačići</b><br>
                        Ovi kolačići su povezani sa funkcijom piksel na društvenoj mreži Facebook (Facebook pixel) i koriste se za preciznije targetiranje ciljne grupe prilikom plasiranja oglasa.
                    </p>

                    <p class="section-subtitle">
                        <b>Kako onemogućiti kolačiće u bilo kom momentu?</b><br>
                        Možete u svakom momentu pretraživača izbrisati ili sprečiti sačuvavanje određenih kolačića na Vašem računaru ili mobilnom uređaju. Deo „Pomoć“ u Vašem pretraživaču treba da sadrži informacije o tome kako da upravljate kolačićima. Međutim treba napomenuti da blokiranje kolačića može uticati na funkcionalnosti sajta.
                        Ako želite da prihvatite samo kolačiće od nas, a ne i kolačiće od treće strane, obično možete (u zavisnosti od pretraživača) iz podešavanja izabrati „Blokiraj kolačiće trećih strana“.
                    </p>

                </div>
            </div>
        </div>
        <!-- Second paragraph section end -->
    </section>
</template>

<script>
import AlternativeHeader from "../components/UI/AlternativeHeader.vue";

export default {
  components: {
    AlternativeHeader
  }
}
</script>