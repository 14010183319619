<template>
    <HeaderSection />

    <div v-if="loading" class="loading vh-100 d-flex justify-content-center align-items-center">
        <img src="../assets/blog/spinner.svg" alt=""/>
    </div>

    <div v-if="error" class="error">
        {{error}}
    </div>

    <div class="container-fluid d-flex justify-content-center my-5">
      <template v-if="post.coverImage">
        <img class="img-fluid mx-h-300 main-image" :src="imageUrlFor(post.coverImage).height(500)" />
      </template>
      <template v-else>
        <img class="img-fluid mx-h-300 main-image" src="../assets/blog/cover-post-img.jpg" />
      </template>
    </div>


    <!-- First title section -->
    <div class="container title-section text-center d-flex flex-column align-items-center mb-3 text-normal">
        <span class="d-inline-block mb-2">{{moment(post.publishedAt).locale('sr').format('LL')}}</span>
        <h1 class="section-title">{{post.title}}</h1>
    </div>
    <!-- First title section end -->
    
    <!-- Paragraph Block -->
    <div class="container my-5">
      <div class="text-content">
        <p class="section-subtitle">
          <SanityBlocks :blocks="blocks" />
        </p>
      </div>
    </div>

</template>

<script>
import HeaderSection from "../components/UI/AlternativeHeader.vue";
import { SanityBlocks } from "sanity-blocks-vue-component";
import sanity from "../client";
import imageUrlBuilder from "@sanity/image-url";
import moment from 'moment';

const imageBuilder = imageUrlBuilder(sanity);

const query = `*[slug.current == $slug] {
  _id,
  title,
  slug,
  shortDescription,
  publishedAt,
  coverImage,
  body, 
 "image": mainImage{
  asset->{
  _id,
  url
}
},
"name":author->name,
"authorImage":author->image
}[0]
`;

export default {
  name: "SingleBlog",
  data() {
    return {
      loading: true,
      post: [],
      blocks: [],
    };
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  created() {
    this.fetchData();
  },
  methods: {
    moment,
    imageUrlFor(source) {
      return imageBuilder.image(source);
    },
    fetchData() {
      this.error = this.post = null;

      sanity.fetch(query, { slug: this.$route.params.slug }).then(
        (post) => {
          this.loading = false;
          this.post = post;
          this.blocks = post.body;
        },
        (error) => {
          this.error = error;
        }
      );
    },
  },

  components: {
    HeaderSection,
    SanityBlocks,
  },
};

</script>

<style scoped>
.mx-h-300{
  min-height: 300px;
  object-fit: cover;
}

.vh-100{
  height: 100vh;
}

.main-image{
  max-width: 1255px;
  height: auto;
}

.section-title{
  text-transform: initial;
}

@media (max-width: 576px) {
  h1{
    font-size: 2rem !important;
  }

  .main-image{
    max-width: 100%;
  }

  .w-75{
    width: 100% !important;
  }
}
</style>