<template>
  <div class="eighth-section container d-flex justify-content-center px-3">
    <div
      class="eighth-section-content position-relative overflow-hidden mx-auto w-lg-50 d-flex flex-column align-items-center">
      <img class="headphones" src="/icon/headphones.svg" alt="Headphones" />
      <h1 class="m-0 mt-2">Kontaktirajte nas</h1>
      <form class="d-flex flex-column align-items-center w-100" @submit.prevent="onSubmit">
        <!-- Email -->
        <div class="form-input-container d-flex flex-column align-items-center">
          <div class="position-relative w-100 h-100">
            <img src="/icon/email.svg" class="position-absolute top-0 start-0" alt="Email" width="24" height="24" />
            <input type="text" @blur="v$.email.$touch" v-model="email" placeholder="Email" />
          </div>
          <p v-for="error of v$.email.$errors" :key="error.$uid" class="error m-0 w-100 text-center">
            {{ error.$message }}
          </p>
        </div>

        <!-- Title -->
        <div class="form-input-container d-flex flex-column align-items-center">
          <div class="position-relative w-100 h-100">
            <img src="/icon/title.svg" class="position-absolute top-0 start-0" alt="Title" width="24" height="24" />
            <input type="text" @blur="v$.title.$touch" v-model="title" placeholder="Naslov" />
          </div>
          <p v-for="error of v$.title.$errors" :key="error.$uid" class="error m-0 w-100 text-center">
            {{ error.$message }}
          </p>
        </div>

        <!-- Text -->
        <div class="form-input-container d-flex flex-column align-items-center">
          <div class="position-relative w-100">
            <img src="/icon/message.svg" class="position-absolute top-0 start-0" alt="Message" width="24" height="24" />
            <textarea @blur="v$.text.$touch" v-model="text" placeholder="Tekst"></textarea>
          </div>
          <p v-for="error of v$.text.$errors" :key="error.$uid" class="error m-0 w-100 text-center">
            {{ error.$message }}
          </p>
        </div>

        <button type="submit" class="d-flex align-items-center bg-white gap-3 mt-3">
          <img src="/icon/submit.svg" alt="Submit" width="24" height="24" />
          Pošaljite
        </button>
      </form>
      <img src="/img/contact-us-bg-1.png" class="bg-image bg-image-first position-absolute bottom-0" />
      <img src="/img/contact-us-bg-2.png" class="bg-image position-absolute bottom-0" />
    </div>
  </div>
</template>

<script>
import { email, required, helpers } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import Swal from "sweetalert2";

export default {
  data() {
    return {
      v$: useVuelidate({ $autoDirty: true }),
      email: "",
      title: "",
      text: "",
    };
  },
  validations() {
    return {
      email: {
        required: helpers.withMessage("Email je potreban", required),
        email: helpers.withMessage("Vrednost nije validna email adresa", email),
      },
      title: {
        required: helpers.withMessage("Naslov je potreban", required),
      },
      text: {
        required: helpers.withMessage("Tekst je potreban", required),
      },
    };
  },
  methods: {
    onSubmit() {
      // this.v$.$reset();
      this.v$.$touch();
      if (this.v$.$invalid) {
        return;
      }
      let data = {
        email: this.email,
        title: this.title,
        text: this.text,
      };
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      };

      // try {
      //   fetch(`url`, requestOptions).then((response) => {
      //     if (response.ok) {
      //       this.alert("Succesfully sent a message!");
      //     } else {
      //       this.alert("The message could not be sent! Please try again!");
      //     }
      //   });
      // } catch (error) {
      //   console.error("Fetch error:", error);
      // }
    },

    alert(title) {
      Swal.fire({
        position: "center",
        title: title,
        // text: "Lorem ipsum",
        showConfirmButton: false,
        timer: 4000,
        // background: "#fff",
        color: "#000",
        customClass: {
          title: "custom-alert-title",
          popup: "custom-alert",
        },
      });
    },
  },
};
</script>

<style scoped>
.eighth-section {
  margin: 4rem auto;
}

.eighth-section .eighth-section-content {
  background: #006d35;
  border-radius: 2rem;
  padding: 2rem 1.5rem;
}

.eighth-section img.headphones {
  width: 60px;
}

.eighth-section h1 {
  font-weight: 700;
  font-size: 2rem !important;
  text-align: center;
}

.eighth-section form {
  gap: 1rem;
  margin-top: 1.5rem;
  z-index: 1;
}

.eighth-section .form-input-container {
  gap: 0.25rem;
  border-radius: 0.5rem;
  width: 100%;
}

.eighth-section .form-input-container div {
  min-height: 56px;
}

.eighth-section .form-input-container img {
  margin: 1rem 12px;
  z-index: 1;
}

.eighth-section .form-input-container input,
.eighth-section .form-input-container textarea {
  border-radius: 0.5rem;
  height: 100%;
  width: 100%;
  border: none;
  color: #191c19;
  background: #dde5da;
  padding: 0.5rem 1rem 0.5rem 3rem;
  resize: none;
}

.eighth-section .form-input-container textarea {
  padding-top: 1rem;
}

.eighth-section .form-input-container div:has(textarea) {
  height: 9.5rem;
}

.eighth-section .form-input-container input:focus,
.eighth-section .form-input-container textarea:focus {
  outline: none;
}

.eighth-section .form-input-container input::placeholder,
.eighth-section .form-input-container textarea::placeholder {
  color: #191c19;
}

.eighth-section .form-input-container .error {
  border: 1px solid rgb(248 113 113);
  background: rgb(254 226 226);
  color: rgb(185 28 28);
  border-radius: 0.5rem;
  font-weight: 300;
  padding: 0.25rem;
}

.eighth-section button {
  text-decoration: none;
  color: #006d35;
  border-radius: 100px;
  padding: 0.625rem 3rem;
  font-weight: 600;
  font-size: 18px;
  border: 0;
}

.eighth-section .bg-image {
  z-index: 0;
}

.eighth-section .bg-image.bg-image-first {
  bottom: 2rem !important;
}

@media (min-width: 1024px) {
  .eighth-section {
    margin: 7.5rem auto;
  }

  .eighth-section .eighth-section-content {
    padding: 2rem;
  }

  .eighth-section img.headphones {
    width: auto;
  }

  .eighth-section h1 {
    font-size: 3rem !important;
  }
}
</style>
<style>
.custom-alert {
  border: 5px solid #006d35 !important;
  border-radius: 1rem;
}

.custom-alert-title {
  font-size: 1.5rem;
}

@media (min-width: 1024px) {
  .custom-alert-title {
    font-size: 2rem;
  }
}
</style>