<template>
  <!--Section One + Navbar Start-->
  <div class="backdrop-image-dark">
    <div class="container h-100 position-relative d-flex flex-column justify-content-center">
      <HeaderSection />
      <!--Title Box-->

    <div id="top-section" class="h-100 d-flex flex-column pt-5 pb-5">
      <div class="d-flex flex-column align-items-start overflow-hidden mb-top">
        <div class="overflow-hidden">
          <h5 class="text-main fw-bold mb-0 text-uppercase slideUpText">{{slider ? slider.subtitle : ''}}</h5>
        </div>
        <div class="overflow-hidden">
          <h1 class="text-light letter-spacing-2 text-uppercase mb-0 slideUpText">{{slider ? slider.title : ''}}</h1>
        </div>
        <div class="overflow-hidden">
          <p class="text-light w-lg-50 slideUpText">{{slider ? slider.text[0]['children'][0]['text'] : ''}}</p>
          <!-- <a :href="slider ? slider.buttonLink : '#'" class="td-none">
            <button :aria-label="slider ? slider.buttonText : ''" class="btn btn-main lh-lg rounded-0 d-flex align-items-center gap-1 slideUpText" type="submit"> {{slider ? slider.buttonText : ''}} <i class="ri-arrow-right-line"></i></button>
          </a> -->
        </div>
      </div>
      <!--Video player-->
      <div class="overflow-hidden blog-post mb-without-menu video-player">
        <div class="d-flex justify-content-center gap-3 slideUpText">
          <video id="player" playsinline data-poster="/path/to/poster.jpg">
            <source src="../../assets/video/euro-petrol-video.mp4" type="video/mp4" />
          </video>
        </div>
      </div>
  </div>

      <!--Scroll Down Button-->
      <a href="#about-euro-petrol" id="scrollDownButton" aria-label="Scroll down button" class="p-4 bg-light position-absolute bottom-0 end-0 mb-without-menu z-top rounded rounded-circle fs-5 d-none d-md-flex">
        <i class="ri-arrow-down-line"></i>
      </a>
      <!--Gas Station Manager Image-->
      <img v-if="slider && slider.image.asset.url"
        class="position-absolute bottom-0 end-0 size-manager"
        :src="slider.image.asset.url"
        alt="OverlayImage"
      />
    </div>
  </div>


  <section id="gas-station-prices" class="py-5 bg-light">
      <div class="container title-section text-center d-flex flex-column align-items-center py-5">
          <h1 class="section-title">Aktuelne cene goriva</h1>
          <p class="section-subtitle text-center w-75">
              Saznajte više informacija o Euro Petrol stanicama, uslugama koje nudimo, i budite u toku sa najnovijim cenama goriva kompanije Euro Petrol na tržištu Republike Srbije.
          </p>
          <a href="/benzinske-stanice" class="text-decoration-none">
            <button class="btn btn-main lh-lg rounded-0 d-flex align-items-center gap-1"> Lista Euro Petrol stanica <i class="ri-arrow-right-line color-secondary"></i></button>
          </a>
      </div>
  </section>
  <!--Section End-->
</template>

<script>
import HeaderSection from "../UI/TheHeader.vue";
import Plyr from "plyr";
import "plyr/dist/plyr.css";
import sanity from "../../client";

const sliderQuery = `*[_type == "sliders"] {
  _id, title, subtitle, text, buttonText, buttonLink,
  "image": mainImage{
        asset->{
        _id,
        url
    },
  },
}[0]`;

export default {
  data() {
    return {
        loading: true,
        slider: null
    };
  },
  components: {
    HeaderSection,
  },
  computed() {
    return {
      player: null,
    };
  },
  async created() {
    await this.fetchData();
  },
  mounted() {
    this.player = new Plyr("#player", {
      controls: [
        "play-large", // The large play button in the center
      ],
    });
    this.player.muted = false;
    this.player.on('play', () => {
      this.player.fullscreen.enter();
      document.body.style.cursor = 'auto';
      this.player.on('pause', () => {
        this.player.fullscreen.exit();
      })
    })
  },
  methods: {
    fetchData() {
      this.error = this.product = null;
      this.loading = true;

      sanity.fetch(sliderQuery).then(
          (slider) => {
              this.loading = false;
              this.slider = slider;
          },
          (error) => {
              this.error = error;
          }
      );
    },
  }
};
</script>

<style>
:root {
  --plyr-video-control-color: #00a651;
  --plyr-control-icon-size: 30px;
  --plyr-video-control-color-hover: #000000cc;
  --plyr-video-control-background-hover: transparent;
}

.plyr--playing{
  cursor: url("../../assets/media/close-button.png"), auto;
}

.backdrop-image {
  height: 100vh;
  width: 100vw;
  background: url("../../assets/media/GasPumpImage1.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
/*--Splash Screen--*/

/*--Tools--*/
.v-line {
  border-left: 1px solid rgb(255, 255, 255, 0.2);
  width: 1px;
  height: 42px;
}

.h-line {
  border-top: 1px solid rgb(255, 255, 255, 0.2);
  width: 100%;
  height: 1px;
}

.h-line-small {
  width: 15px;
  height: 1px;
  border-top: 1px solid var(--second-color);
}

.z-top {
  z-index: 2;
}

.ps-20 {
  padding-left: 20%;
}

.bottom-negative-10 {
  bottom: -10%;
}

.blog-post {
  width: 18.63em;
  height: 10.42em;
}

#scrollDownButton {
  width: 4.17em;
  height: 4.17em;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: inherit;
}

#scrollDownButton:hover {
  transform: scale(1.2);
  transition: all 0.2s ease-in-out;
  background-color: var(--color-main) !important;
  color: white;
}

.td-none{
  text-decoration: none;
}

.w-lg-50 {
  width: 50%;
}

.w-lg-50 {
  width: 50%;
}

.size-manager {
  width: auto;
  height: 75vh;
}
/*--Tools--*/

.plyr__control{
  background: white !important;
}

.slideUpText{
  -webkit-animation: slideUpText 500ms ease-in-out; /* Chrome, Safari, Opera */
  animation: slideUpText 500ms ease-in-out;
}

@keyframes slideUpText{
  0%{
    opacity: 0;
    transform: translateY(100%) translateZ(0px);
  }
  100%{
    opacity: 1;
    transform: translateY(0%) translateZ(0px);
  }
}

@-webkit-keyframes slideUpText{
  0%{
    opacity: 0;
    transform: translateY(100%) translateZ(0px);
  }
  100%{
    opacity: 1;
    transform: translateY(0%) translateZ(0px);
  }
}

@media (max-width:992px) {
  .size-manager {
    width: auto;
    height: 50vh;
  }

  .w-lg-50 {
    width: 100%;
  }

  .mb-top{
    z-index: 1;
    /* margin-top: 20% !important; */
  }

  .blog-post{
    z-index: 1;
  }

  .video-player{
    display: none;
    opacity: 0;
  }

  #gas-station-prices .w-75{
    width: 100% !important;
  }
}

#top-section {
  justify-content: space-between;
  gap: 15px;
}

@media (max-width: 992px) {
    #top-section {
      justify-content: start;
    }
}

@media (min-height: 700px) and (max-height: 800px) {
  .size-manager{
    right: 70px !important;
  }
  #scrollDownButton{
    right: 20px !important;
  }
}

.my-auto-without-menu {
  margin-top: 150px;
  margin-bottom: auto;
}

.mb-without-menu {
  margin-bottom: 8rem !important;
}
</style>