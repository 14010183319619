<template>
    <!-- Newsletter Section Start -->
    <section id="newsletter" class="d-flex justify-content-center pt-5 newsletter-bg">
        <div class="d-flex flex-column justify-content-center align-items-center p-5 vh-50">
            <h1 class="section-title color-main text-center text-light">Ostanimo u kontaktu</h1>
            <p class="section-subtitle text-center text-light mt-0">Budite u toku sa najnovijim vestima i novostima, zapratite nas na našim društvenim mrežama.</p>
            <div class="d-flex gap-3 flex-wrap justify-content-center">
                <a href="https://www.instagram.com/euro.petrol/" rel="noopener" target="_blank" class="btn btn-secondary lh-lg rounded-0 d-flex align-items-center gap-1 text-capitalize" type="submit"> <i class="ri-instagram-line fs-4"></i> Instagram </a>
                <a href="https://www.facebook.com/EuroPetrolSrbija" rel="noopener" target="_blank" class="btn btn-secondary lh-lg rounded-0 d-flex align-items-center gap-1 text-capitalize" type="submit"> <i class="ri-facebook-fill fs-4"></i> Facebook </a>
                <a href="https://www.youtube.com/user/europetrolsubotica" rel="noopener" target="_blank" class="btn btn-secondary lh-lg rounded-0 d-flex align-items-center gap-1 text-capitalize" type="submit"> <i class="ri-youtube-line fs-4"></i> YouTube </a>
            </div>
        </div>
    </section>
    <!-- Newsletter Section End -->
</template>

<script>
export default {
    setup() {
        
    },
}
</script>

<style scoped>
.newsletter-bg{
    background: url('../../assets/media/newsletter-sign-up.jpg');
    background-position: 50% 50%;
    background-size: cover;
}
.vh-50{
    height: 75vh;
}

.btn-secondary:hover{
  color: #eee;
  background-color: var(--color-main-hover) !important;
  border: 1px solid var(--color-main-hover);
}

.btn-secondary{
  background-color: transparent;
  border: 1px solid rgba(255, 255, 255, 0.4);
  color: white;
}

@media (min-width: 1000px) {
  .section-subtitle{
      width: 30vw;
  }
}

@media (max-width: 750px){
    .newsletter-bg{
        background-position: 75% 50%;
    }
}
</style>